export const SET_CARRINHO = 'SET_CARRINHO';
export const SET_CONFIGURACOES = 'SET_CONFIGURACOES';
export const SET_IDEMPRESA = 'SET_IDEMPRESA';
export const SET_BANNERS = 'SET_BANNERS';
export const SET_CARDAPIO = 'SET_CARDAPIO';
export const SET_QTDITENS = 'SET_QTDITENS';
export const CALCULAR_TOTAL_PEDIDO = 'CALCULAR_TOTAL_PEDIDO';
export const SET_FILTROS = 'SET_FILTROS';
export const SET_VISIBLE_MODAL_X = 'SET_VISIBLE_MODAL_X';


