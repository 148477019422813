import { SET_CONFIGURACOES } from '../actions/actionTypes';
import { SET_IDEMPRESA } from '../actions/actionTypes';

const initialState = {
    idEmpresa: 0,
    configuracoes: { 
        logo: "", telefone: "", telefoneFormatado: "", 
        cor: { primaria: "#000", secundaria: "#FFF" }, 
        endereco: { cep: "" },
        meioPagamentos: []
    },
};

export const reducerConfiguracoes = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONFIGURACOES:
            return {
                ...state,
                configuracoes: action.configuracoes
            };
        case SET_IDEMPRESA:
            return {
                ...state,
                idEmpresa: action.idEmpresa
            };
        default:
            return state;
    }
};