import { Route } from 'react-router-dom'
import React from 'react';

import PaginaInicial from './pages/pagina-inicial/PaginaInicial';
import Pedidos from './pages/pedidos/Pedidos';
import Gerenciar from './pages/gerenciar/Gerenciar';


class Rotas extends React.Component {
    render() {
        return (
            <Route>
                <Route exact path='/' component={PaginaInicial} />
                <Route exact path='/pedidos' component={Pedidos} />
                <Route exact path='/gerenciar' component={Gerenciar} />
                
            </Route>
        )
    }
}

export default Rotas;
