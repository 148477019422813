import React from 'react';
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import 'rc-banner-anim/assets/index.css';
import './Banner.css';
const BgElement = Element.BgElement;
class Banner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    renderElement = function () {
        
        return this.props.banners.map((banner) => {
            var background = "";
            let url = banner.imgMobile;
            if(window.innerWidth >= 724){
                url = banner.img;
            }

            if(url.indexOf("http") !== 0){
                url = `${process.env.PUBLIC_URL}/imagens/${banner.img}`
            }

            background =  `url(${url})`;
            
            return (
                <Element
                    prefixCls="banner-user-elem"
                    key="0"
                    id={this.props.id}
                >
                    
                    <BgElement
                        key="bg"
                        className={"bg "+this.props.className}
                        id={this.props.id}
                        

                        style={{
                            
                            background: background,
                            backgroundPosition: 'center center',
                            backgroundSize: 'cover'
                        }}
                    />


                    <TweenOne className="banner-user-title" animation={{ y: 30, opacity: 0, type: 'from' }}>
                        {banner.titulo}
            </TweenOne>
                    <TweenOne className="banner-user-text"
                        animation={{ y: 30, opacity: 0, type: 'from', delay: 100 }}
                    >
                        {banner.descricao}
            </TweenOne>
                </Element >
            )
        })
    }

    render() {
        if(this.props.banners){
        return (
            <BannerAnim id={this.props.id} type={this.props.type} prefixCls={this.props.prefixCls}>
                {this.renderElement()}
            </BannerAnim>);
        }else{
            return;
        }
    }
}

export default Banner;