export  function  renderEndereco(endereco) {
    var txt = "";
    if (endereco.numero) {
        txt = `<h1><strong>ENDEREÇO</strong></h1>`
        if (endereco.nomeDestinatario) { txt += `<strong>Entregar para</strong>: ${endereco.nomeDestinatario} <br/>` };
        if (endereco.cep) { txt += `<strong>Cep</strong>: ${endereco.cep} <br/>` };
        if (endereco.logradouro) { txt += `<strong>Rua/Avenida</strong>:  ${endereco.logradouro} <br/>` };
        if (endereco.numero) { txt += `<strong>Numero</strong>: ${endereco.numero} <br/>` };
        if (endereco.bairro) { txt += `<strong>Bairro</strong>: ${endereco.bairro} <br/>` };
        if (endereco.cidade) { txt += `<strong>Cidade</strong>: ${endereco.cidade} - ${endereco.estado} <br/>` };
        if (endereco.complemento) { txt += `<strong>Complemento</strong>: ${endereco.complemento} <br/>` };
        if (endereco.distancia) { txt += `<strong>Distancia Aproximada de</strong> : ${endereco.distancia} Km <br/>` };
        return txt;
    }
}