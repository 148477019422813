import React from 'react';
import 'rc-banner-anim/assets/index.css';
import './ModalPedidoX.scss';
import { Modal, Row, Col, Checkbox } from 'antd';
//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setVisibleModalX } from '../../actions';
import { renderCheckboxesSelecionados } from '../../functions/renderCheckboxesSelecionados'
import { renderRadios } from '../../functions/renderRadios'
import { renderEndereco } from '../../functions/renderEndereco'
import { renderTotal } from '../../functions/renderTotal'
import { renderItem } from '../../functions/renderItem'
import { renderObservacao } from '../../functions/renderObservacao'
import BotoesPedidoStatus from '../../components/botoesPedidoStatus/BotoesPedidoStatus';

/* <label for="statusPedido">Status do Pedido</label>: <StatusPedido id="statusPedido" statusInicial={x.status} changeStatus={(e) => this.changeStatus(e, key)} defaultValue="em_aberto" /> */
class ModalPedidoX extends React.Component {
    handleOk = e => {
        const { id } = this.props;
        window.setTimeout(() => this.props.setVisibleModalX(id, false), 100);
    };

    handleCancel = e => {
        console.log("ok")
        const { id } = this.props;
        window.setTimeout(() => this.props.setVisibleModalX(id, false), 100);
        console.log("fim")
    };

    renderPedido() {
        const { x } = this.props;
        console.log("x", x);

        const pedido = JSON.parse(x.carrinho);

        return pedido.map(function (item) {
            return <Row>
                <Col xs={2} style={{background: "#555"}}>
                    <Checkbox />
                </Col>
                <Col xs={12} style={{ background: "#EEE", display: "inline-block", margin: "3px", padding: "5px", borderLeft: "3px solid #555" }} key={x}>

                    <div dangerouslySetInnerHTML={{ __html: renderItem(item) }}></div>
                    <div dangerouslySetInnerHTML={{ __html: renderRadios(item) }}></div>
                    <div style={{ marginBottom: "0px" }} dangerouslySetInnerHTML={{ __html: renderCheckboxesSelecionados(item) }}></div>
                    <div dangerouslySetInnerHTML={{ __html: renderObservacao(item) }}></div>
                </Col>
            </Row>
        })
    }

    renderEndereco() {
        const { x } = this.props;
        return <p dangerouslySetInnerHTML={{ __html: renderEndereco(x.endereco) }}></p>
    }

    renderTotalPedido() {
        const { total } = this.props;
        return <p dangerouslySetInnerHTML={{ __html: renderTotal(total) }}></p>
    }

    render() {
        const { id } = this.props;
        return (
            <>
                <Modal
                    className="print pgModalPedidoX"
                    id={id}
                    title=""
                    visible={this.props.arrayVisible[id]}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={1000}
                >
                    <div className="botoesPedidoStatus" >
                        <BotoesPedidoStatus />
                    </div>

                    <Row>
                        <Col sm={12}>
                            <h1><strong>ITENS</strong></h1>
                            {
                                this.renderPedido()
                            }

                            {
                                this.renderTotalPedido()
                            }
                        </Col>
                        <Col sm={12}>
                            {
                                this.renderEndereco()
                            }
                        </Col>
                    </Row>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = store => ({
    arrayVisible: store.setVisibleModalX.arrayVisible
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setVisibleModalX }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalPedidoX);