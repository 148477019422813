import React from 'react';
import './MenuPrincipal.css';
import { Layout, Avatar } from 'antd';


class MenuPrincipal extends React.Component {


  render() {

    return (
      <div>
        <Layout.Header className="Header">

          <div className="divLogoNucardapio" >
            <img alt="Logo" src={"./imagens/logos/logo-nucardapio-light.png"} width="100px" />
          </div>
          
          
          <Avatar size={{xs: 24, sm: 32, md: 40, lg: 64, xl: 70, xxl: 70}} style={(this.props.comanda > 0) ? {marginTop: "5px", lineHeight: "1", backgroundColor: "transparent", float: "right", color: "#FFF", fontSize: "24pt" } : {display: "none"}}>
            <span style={{fontSize: "12pt", marginTop: "-2px", display: "inline-block"}}>comanda</span><br/>
            {this.props.comanda}
          </Avatar>

        </Layout.Header>


      </div>
    );
  }
}

export default MenuPrincipal;