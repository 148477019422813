export  function  renderRadios(item) {
    if (item.radiosSelecionados) {
        return Object.keys(item.radiosSelecionados).map(function (titulo) {
            var objRadio = JSON.parse(item.radiosSelecionados[titulo]);
            var txt = `<strong>${titulo}</strong> : ${objRadio.nome}`
            if (!objRadio.substituirValorOriginal) {
                txt += ` - <span class='preco'>R$ ${parseFloat(JSON.parse(item.radiosSelecionados[titulo]).valor).toFixed(2)}</span>`
            }
            return txt;
        })
    }
}