import React from 'react';
import { Row, Col, Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCarrinho,setQtdItens,calcularTotalPedido } from '../../actions';
import getIdProduto from '../../functions/getIdProduto'

class Carrinho extends React.Component {
    removerCarrinho = function (produto) {
        var novoCarrinho = this.props.carrinho;
        let qtdItens = this.props.qtdItens;

        qtdItens -= novoCarrinho[getIdProduto(produto)].qtd;
        delete novoCarrinho[getIdProduto(produto)];

        this.props.setCarrinho(novoCarrinho)
        this.props.setQtdItens(qtdItens);

        this.props.calcularTotalPedido(novoCarrinho)
    }

    render() {
        let keys = Object.keys(this.props.carrinho);
        let retorno = [];

        if (keys.length > 0) {
            retorno.push(<Row key="row1" style={{ "marginTop": "50px" }}><Col span={24}><h1 style={{ "textAlign": "center" }}>Itens No Carrinho</h1></Col></Row>)
        }

        keys.forEach((key, index) => {
            let carrinho = this.props.carrinho[key];

            retorno.push(<Row key={index} className="box item">
                <Col span={24}>
                    <span className="DescItemCarrinho">{carrinho.nome} ({carrinho.qtd}xR${parseFloat(carrinho.preco).toFixed(2)} )</span> <span className="preco">R$ {
                        parseFloat(carrinho.preco * carrinho.qtd).toFixed(2)
                    }</span>
                    {carrinho.descricao}

                    {

                        (carrinho.radiosSelecionados) ?
                            Object.keys(carrinho.radiosSelecionados).map(function (titulo) {
                                var objRadio = JSON.parse(carrinho.radiosSelecionados[titulo]);
                                return (<div style={{ marginTop: "10px" }}>
                                    <strong>{titulo}</strong>
                                    <p>{objRadio.nome} {(!objRadio.substituirValorOriginal) ? <strong> - R$ {parseFloat(JSON.parse(carrinho.radiosSelecionados[titulo]).valor).toFixed(2)}</strong> : ""}</p>
                                </div>)
                            })
                            : ""
                    }

                    {
                        (carrinho.checkboxesSelecionados) ?
                            Object.keys(carrinho.checkboxesSelecionados).map(function (titulo) {
                                return carrinho.checkboxesSelecionados[titulo].map(function (strObj) {
                                    var checkbox = JSON.parse(strObj);
                                    return (<div style={{ marginTop: "10px" }}>
                                        <strong>{titulo}</strong>
                                        <p>{carrinho.qtd}x {checkbox.nome} <strong> - R$ {parseFloat(carrinho.qtd * JSON.parse(strObj).valor).toFixed(2)}</strong></p>
                                    </div>)
                                })
                            })
                            : ""
                    }

                    {
                        (carrinho.adicionalObs) ?
                            <p>OBS: {carrinho.adicionalObs}</p>
                            : ""
                    }


                    <br></br>

                    <Button className="addButton" style={{ borderColor: this.props.configuracoes.cor.primaria }} onClick={() => this.removerCarrinho(carrinho)}>Excluir do pedido</Button>
                </Col>
            </Row>)
        });

        return retorno.map(ret => {
            return ret;
        })
    }

}


const mapStateToProps = store => ({
    carrinho: store.setCarrinho.carrinho,
    qtdItens: store.setCarrinho.qtdItens,
    configuracoes: store.setConfiguracoes.configuracoes
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setCarrinho,setQtdItens,calcularTotalPedido }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Carrinho);