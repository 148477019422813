import { SET_BANNERS } from '../actions/actionTypes';

const initialState = {
    banners: []
};

export const reducerBanners = (state = initialState, action) => {
    switch (action.type) {
        case SET_BANNERS:
            return {
                banners: action.banners
            };
        default:
            return state;
    }
};