import { 
    SET_VISIBLE_MODAL_X
} from '../actions/actionTypes';

const initialState = {
    id: 0,
    arrayVisible: [],
};

export const reducerModalX = (state = initialState, action) => {
    console.log("action",action)
    var setVisibleModal = function (id,visibilidade) {
        console.log("visibilidade",visibilidade);
        var arr = []
        arr[id] = visibilidade;
        return {
            ...state,
            arrayVisible: arr
        };
    }

    switch (action.type) {
        case SET_VISIBLE_MODAL_X: return setVisibleModal(action.id,action.visibilidade)
        default:
            return state;
    }
};