import React from 'react';
import 'rc-banner-anim/assets/index.css';
import './BotoesPedidoStatus.css';
import { Button } from 'antd';
import Icon from '@ant-design/icons';

class Banner extends React.Component {
    imprimir = function (x) {
       // this.setState({ objSelecionado: x })
        window.setTimeout(() => window.print(), 1500);
    }
    
    render() {
        const {
            x
        } = this.props;

        return (
            <>
                <Button className="btnImprimir" onClick={() => this.imprimir(x)}><Icon type="printer" /> IMPRIMIR</Button>
                {/*<Button><Icon type="smile" /> PREPARANDO PEDIDO</Button>
                <Button><Icon type="heart" /> SAIU PARA ENTREGA</Button>
        <Button><Icon type="like" /> ENTREGUE</Button>*/}
                
            </>
        );
    }
}

export default Banner;