import { 
    SET_CARRINHO, 
    SET_QTDITENS,
} from '../actions/actionTypes';

const initialState = {
    carrinho: [],
    qtdItens: 0,
    valorTotalPedido: 0,
};

export const reducerCarrinho = (state = initialState, action) => {
    switch (action.type) {
        case SET_CARRINHO:
            return {
                ...state,
                carrinho: action.carrinho
            };
        case SET_QTDITENS:
            return {
                ...state,
                qtdItens: action.qtdItens
            };
          
        default:
            return state;
    }
};