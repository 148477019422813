import axios from 'axios';

export function getSubdominio() {
    var dominio = /:\/\/([^\/]+)/.exec(window.location.href)[1];
    var subdominio = dominio.split(".")[0];
    return subdominio;
}

export function estaLocal() {
    var subdominio = getSubdominio();
    if (subdominio == "localhost:3000") {
        return true;
    } else {
        return false;
    }
}



export async function getIdEmpresa(idEmpresa = "") {
    var subdominio = getSubdominio();
    var url_parte2 = "/-1/getEmpresa?subdominio=" + subdominio;
    var url_parte2_localhost = "/-1/getEmpresa?subdominio=localhost:3000";
    var retorno = null;

    if (!estaLocal()) {
        retorno = await axios.get("https://apinucardapio.emitirnotas.com.br" + url_parte2);
    } else {
        retorno = await axios.get("http://localhost:8082" + url_parte2_localhost);
    }

    if (idEmpresa == "en") {
        return retorno.data.empresa;
    } else {
        return retorno.data.id;
    }
}

export async function getUrlApiEmitirNotas() {
    var idEmpresa = await getIdEmpresa("en")
    if (estaLocal()) {
        return "http://localhost:8081/en-publico/" + idEmpresa;
    } else {
        return "https://api.emitirnotas.com.br/publico/" + idEmpresa;
    }
}


export async function getUrlApiNucardapio() {
    var url = null;
    var idEmpresa = await getIdEmpresa()
    if (!estaLocal()) {
        //producao
        url = "https://apinucardapio.emitirnotas.com.br/" + idEmpresa + "/";
    } else {
        //localhost
        url = "http://localhost:8082/" + idEmpresa + "/";
    }
    return url;
}