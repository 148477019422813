import axios from 'axios';
//ANT D
import { notification, message, Layout, Row, Col, Button, Modal, Checkbox, Select, Input, Radio, Form, Alert } from 'antd';
import { MinusOutlined, PlusOutlined, ShoppingCartOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import TextArea from 'antd/lib/input/TextArea';
//REACT
import React, { useEffect, useState/* , useRef */ } from 'react';
//CSS
import './PaginaInicial.scss';
//COMPONENTS
import Banner from '../../components/banner/Banner';
import MenuPrincipal from '../../components/menu-principal/MenuPrincipal';
import TabFiltros from '../../components/tab-filtros/TabFiltros';
import Empresa from '../../components/empresa/Empresa';
//PAGES
import PaginaCarrinho from '../../pages/pagina-carrinho/PaginaCarrinho';
//FUNCOES
import { getUrlApiNucardapio, getUrlApiEmitirNotas } from '../../_environments/ConfigAPI'
import axiosGet from '../../functions/axiosGet'
import getIdProduto from '../../functions/getIdProduto'
//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCarrinho, setQtdItens, calcularTotalPedido, setFiltros } from '../../actions';
import { IMaskInput } from 'react-imask';
import { FormItemBairro } from '../../components/select-bairros'
import { useLocation } from "react-router-dom";


const { Option } = Select;

const mapStateToProps = store => ({
    carrinho: store.setCarrinho.carrinho,
    qtdItens: store.setCarrinho.qtdItens,
    configuracoes: store.setConfiguracoes.configuracoes,
    idEmpresa: store.setConfiguracoes.idEmpresa,
    banners: store.setBanners.banners,
    cardapio: store.setCardapio.cardapio,
    valorTotalPedido: store.calcularTotalPedido.valorTotalPedido,
    filtros: store.setFiltros.filtros
})

const PaginaInicial = (props) => {

    //const ref = useRef(null);

    const [verCarrinho, setVerCarrinho] = useState(0)
    const [visible, setVisible] = useState(false);
    const [itemSelecionado, setItemSelecionado] = useState({ adicional: [], adicionalObs: "", radiosSelecionados: {} });
    const [radiosSelecionados, setRadiosSelecionados] = useState({})
    const [checkboxesSelecionados, setCheckboxesSelecionados] = useState({})
    const [adicionais, setAdicionais] = useState([])
    const [radios, setRadios] = useState([])
    const [radiosFilho, setRadiosFilho] = useState([])
    const [qtdTempAdicional, setQtdTempAdicional] = useState(1)
    const [adicionalChecked, setAdicionalChecked] = useState([])
    const [tipoPagamento, setTipoPagamento] = useState("")
    const [vaiPrecisarTroco, setVaiPrecisarTroco] = useState("nao")
    const [trocoPara, setTrocoPara] = useState("")
    const [valorDeTroco, setValorDeTroco] = useState(0)
    const [visibleModalFormaPagamento, setVisibleModalFormaPagamento] = useState(false)
    const [visibleModalFormEntrega, setVisibleModalFormEntrega] = useState(false)
    const [vaiRetirarNoLocal, setVaiRetirarNoLocal] = useState("nao")
    const [distancia, setDistancia] = useState(0)
    const [endereco, setEndereco] = useState({ cep: "", logradouro: "", complemento: "", bairro: "", cidade: "", estado: "", numero: "" })
    const [idPedido, setIdPedido] = useState(0)
    const [loading, setLoading] = useState(false)
    const [dataEncomendaEhValida, setDataEncomendaEhValida] = useState(false)
    const [cliente, setCliente] = useState({})
    const [valorFrete, setValorFrete] = useState(0);
    const [telefoneDestinatario, setTelefoneDestinatario] = useState("");
    const [comanda, setComanda] = useState(0);
    const [visibleModalValidacaoQRCode, setVisibleModalValidacaoQRCode] = useState(false);
    const [codigoValidacao, setCodigoValidacao] = useState("");
    const [mesa, setMesa] = useState("");

    const getDiaSemana = function () {
        let diaSemana = new Date().getDay();
        switch (diaSemana) {
            case 0:
                return "Domingo";
            case 1:
                return "Segunda-Feira";
            case 2:
                return "Terça-Feira";
            case 3:
                return "Quarta-Feira";
            case 4:
                return "Quinta-Feira";
            case 5:
                return "Sexta-Feira";
            case 6:
                return "Sábado";
        }
    }

    const limpaEndereco = function () {
        setValorFrete(0);
        setEndereco({ cep: "", logradouro: "", complemento: "", bairro: "", cidade: "", estado: "", numero: "", nomeDestinatario: endereco.nomeDestinatario });
    }

    const verificaExisteDadosLocaisSalvo = function () {
        if (localStorage.getItem("endereco")) {
            var endereco = JSON.parse(localStorage.getItem("endereco")).endereco;
            setEndereco(endereco);
        }

        if (localStorage.getItem("telefoneDestinatario") > 0) {
            var telefoneDestinatario = JSON.parse(localStorage.getItem("telefoneDestinatario"));
            setTelefoneDestinatario(telefoneDestinatario)
        }
    }

    /*setCoresForm() {
        
        Array.from(document.getElementsByTagName("label")).forEach(function (item) {
            item.style.color = props.configuracoes.cor.secundaria;
        });
    }*/

    const verificaFreteFixo = function () {

        if (props.configuracoes.valorFreteFixo > 0) {

            setValorFrete(props.configuracoes.valorFreteFixo)
            console.log("valorFreteFixo", props.configuracoes.valorFreteFixo)
        }
    }

    const getComanda = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const comandaParam = parseFloat(urlParams.get('m')) / 12345678910;

        return comandaParam;

    }
    const verificaExisteComanda = () => {
        var comandaParam = getComanda();
        if (comandaParam > 0) {
            if (Number.isInteger(comandaParam)) {
                setComanda(comandaParam);
                return true;
            } else {
                alert("codigo da comanda inválido.");
                window.location.href = "https://www.google.com.br";
            }
        } else {
            localStorage.removeItem("mesa")
        }
        return false;
    }

    const findOrCreateCodigoValidacao = async () => {
        var url = "codValidacaoComanda";
        var param = {
            numComanda: getComanda()
        }
        var retorno = await axiosPost(url, param);
        return retorno;
    }

    const verificaCodValidacaoLocalStorage = async () => {
        const codigoValidacaoLS = localStorage.getItem("codigoValidacao");
        var codigoValidacaoBD = await findOrCreateCodigoValidacao();
        codigoValidacaoBD = codigoValidacaoBD[0].codValidacao;

        if (codigoValidacaoLS != codigoValidacaoBD) {
            setVisibleModalValidacaoQRCode(true);
            return false;
        }

        return true;
    }

    const verificaSeLojaFechada = () => {
        if (props.configuracoes.lojaFechada) {
            var diaSemana = getDiaSemana();




            //por diaSemana
            if (props.configuracoes.lojaFechada.arrayDiaSemana) {
                if (props.configuracoes.lojaFechada.arrayDiaSemana.includes(diaSemana)) {
                    return { mensagem: "A loja está fechada de " + diaSemana };
                }
            }

            //por data
            if (props.configuracoes.lojaFechada.arrayData) {
                if (props.configuracoes.lojaFechada.arrayData.includes(getDataAtual())) {
                    return { mensagem: "Desculpe, a loja está fechada hoje." };
                }
            }

            //por horario
            if (props.configuracoes.lojaFechada.arrayHorario) {
                var horaAtual = getHoraAtual();
                /* alert(horaAtual) */
                horaAtual = horaAtual.split(":");
                horaAtual = horaAtual[0] * 3600 + horaAtual[1] * 60 + horaAtual[2]
                var result = false;
                props.configuracoes.lojaFechada.arrayHorario.forEach((horario) => {
                    if (result) return;
                    var horarioDe = horario.de.split(":");
                    var horarioAte = horario.ate.split(":");
                    horarioDe = horarioDe[0] * 3600 + horarioDe[1] * 60 + horarioDe[2]
                    horarioAte = horarioAte[0] * 3600 + horarioAte[1] * 60 + horarioAte[2]
                    console.log(horarioDe, horarioAte, horaAtual)
                    if (horaAtual > horarioDe && horaAtual < horarioAte) {
                        result = { mensagem: "Desculpe, a loja está fechada das " + horario.de + " às " + horario.ate };
                    }
                })
                return result;
            }


            try {
                var arrayVerificar = [];
                switch (diaSemana) {
                    case "Domingo":
                        arrayVerificar = props.configuracoes.lojaFechada.arrayDomingo;
                    case "Segunda-Feira":
                        arrayVerificar = props.configuracoes.lojaFechada.arraySegunda;
                    case "Terça-Feira":
                        arrayVerificar = props.configuracoes.lojaFechada.arrayTerca;
                    case "Quarta-Feira":
                        arrayVerificar = props.configuracoes.lojaFechada.arrayQuarta;
                    case "Quinta-Feira":
                        arrayVerificar = props.configuracoes.lojaFechada.arrayQuinta;
                    case "Sexta-Feira":
                        arrayVerificar = props.configuracoes.lojaFechada.arraySexta;
                    case "Sábado":
                        arrayVerificar = props.configuracoes.lojaFechada.arraySabado;
                }

                //por array da semana a verificar
                if (arrayVerificar) {
                    var horaAtual = getHoraAtual();
                    /* alert(horaAtual) */
                    horaAtual = horaAtual.split(":");
                    horaAtual = horaAtual[0] * 3600 + horaAtual[1] * 60 + horaAtual[2]
                    var result = false;
                    arrayVerificar.forEach((horario) => {
                        if (result) return;
                        var horarioDe = horario.de.split(":");
                        var horarioAte = horario.ate.split(":");
                        horarioDe = horarioDe[0] * 3600 + horarioDe[1] * 60 + horarioDe[2]
                        horarioAte = horarioAte[0] * 3600 + horarioAte[1] * 60 + horarioAte[2]
                        console.log(horarioDe, horarioAte, horaAtual)
                        if (horaAtual > horarioDe && horaAtual < horarioAte) {
                            result = { mensagem: "Desculpe, a loja está fechada das " + horario.de + " às " + horario.ate };
                        }
                    })
                    return result;
                }
            } catch (error) {
                console.log("errorVerificarLojaFechada", error)
            }



        }
        return false;
    }

    useEffect(() => {

        verificaExisteDadosLocaisSalvo();
        verificaExisteComanda()

    }, [])


    /*  useEffect(() => {
         verificaSeLojaFechada()
     }, [props.configuracoes]) */

    useEffect(() => {
        localStorage.setItem("endereco", JSON.stringify({ endereco: endereco }));
    }, [endereco])

    useEffect(() => {
        localStorage.setItem("telefoneDestinatario", JSON.stringify(telefoneDestinatario));
    }, [telefoneDestinatario])

    useEffect(() => {
        if (itemSelecionado.id) {
            setVisible(true)
            montaTelaModalDeAdicionaisDoItem();
        }
    }, [itemSelecionado])

    const limparRadiosSelecionado = function () {
        setRadiosSelecionados({})
        setRadiosFilho({});
    }

    const limparCheckboxesSelecionado = function () {
        setCheckboxesSelecionados({});
    }

    const renderBtnAddCarrinho = function (item) {
        if (!props.configuracoes.somenteVisualizacao) {
            return <div className="btnAddCarrinho">
                <Button className="addButton" style={{ borderColor: props.configuracoes.cor.primaria }} onClick={() => removerUmItem(item)}><MinusOutlined /></Button>
                <span className="qtdItem" >
                    {getQtdCarrinhoItem(item)}
                </span>
                <Button className="addButton" style={{ borderColor: props.configuracoes.cor.primaria }} onClick={() => addCarrinho(item)}><PlusOutlined /></Button>
            </div>
        } else {
            return <></>
        }
    }

    const renderCardapio = function () {
        var tituloAux = "";

        return props.cardapio.map((item, index) => {
            return (
                <div key={index} className='item'>
                    {
                        /* primeiro titulo */
                        (tituloAux === "" && item.titulo === "ATENCAO")
                            ? <div className="hideShowTabs divAtencao" style={{ background: props.configuracoes.cor.primaria, borderColor: props.configuracoes.cor.secundaria }}><h1 className="title"><span style={{ color: props.configuracoes.cor.secundaria }}>COMO FUNCIONA?</span></h1></div>
                            :
                            /* demais titulos */
                            (tituloAux !== item.titulo) ?
                                <Row className={"hideShowTabs " + item.titulo.replace(" ", "")}>
                                    <Col span={24} ><h1 id={item.titulo} className="title" style={{ borderColor: props.configuracoes.cor.primaria }}>{item.titulo}</h1></Col>
                                </Row>
                                : ""
                    }


                    {
                        <div style={{ display: "none" }}>{tituloAux = item.titulo}</div>

                    }

                    {(item.titulo !== "ATENCAO") ?

                        <Row className={"hideShowTabs box " + item.titulo.replace(" ", "")}>

                            <Col span={24} >
                                <div className="renderCardapio">
                                    <h1>{item.nome}</h1> <span className="preco"><strong>R$ {item.preco}</strong></span>
                                    <span className="descricaoItem">{item.descricao}</span>

                                    {
                                        (returnLojaFechada) ?
                                            ""
                                            : (item.temAdicional) ?
                                                <Button className="addButton" style={{ float: "right", borderColor: props.configuracoes.cor.primaria }} onClick={() => openModalAdicionalDoItem(item)}>
                                                    {
                                                        (!props.configuracoes.somenteVisualizacao) ?
                                                        "ADICIONAR"
                                                        :
                                                        "MAIS OPÇÕES"
                                                    }
                                                </Button>
                                                :
                                                renderBtnAddCarrinho(item)
                                    }
                                </div>
                            </Col>
                        </Row>
                        :

                        <Row className={"hideShowTabs box " + item.titulo.replace(" ", "")}>
                            <Col>
                                <div className="renderCardapio">
                                    <span className="DesAtencao">{item.descricao}</span>
                                </div>
                            </Col>
                        </Row>


                    }
                </div>
            )
        })
    }



    const addCarrinho = function (produto) {

        var carrinho = props.carrinho;
        var qtdItens = props.qtdItens;
        var multiplicadorQtd = 1;

        try {
            var config = JSON.parse(produto.configuracoes);
            if (config && config.multiplicadorQtd) {
                multiplicadorQtd = parseFloat(config.multiplicadorQtd);
            }
        } catch (e) {

        }

        if (!produto.qtdAdicional) {
            if (multiplicadorQtd != 1) {
                produto.qtdAdicional = 1 * multiplicadorQtd;
            } else {
                produto.qtdAdicional = 1;
            }
        }

        //se ja existe o produto no carrinho
        if (carrinho[getIdProduto(produto)]) {
            carrinho[getIdProduto(produto)].qtd += produto.qtdAdicional;
        } else {
            carrinho[getIdProduto(produto)] = produto;
            carrinho[getIdProduto(produto)].qtd = produto.qtdAdicional;
        }
        qtdItens += produto.qtdAdicional;

        props.setQtdItens(qtdItens);
        props.setCarrinho(carrinho);
        props.calcularTotalPedido(carrinho)
    }

    const removerUmItem = (item) => {
        let novoCarrinho = props.carrinho;
        let qtdItens = props.qtdItens;
        var multiplicadorQtd = 1;

        try {
            if (item.hasOwnProperty("configuracoes")) {
                var config = JSON.parse(item.configuracoes);
                if (config.multiplicadorQtd) {
                    multiplicadorQtd = parseFloat(config.multiplicadorQtd);
                }
            }
        } catch (e) {

        }
        if (temProdutoNoCarrinho(item)) {
            if (parseFloat(novoCarrinho[getIdProduto(item)].qtd) === multiplicadorQtd) {
                delete novoCarrinho[getIdProduto(item)];

            } else {
                novoCarrinho[getIdProduto(item)].qtd -= multiplicadorQtd;
            }
            qtdItens -= multiplicadorQtd;
            props.calcularTotalPedido(novoCarrinho);

            props.setCarrinho(novoCarrinho);
            //setState({ qtdItens });
            props.setQtdItens(qtdItens);
        }

        return;
    }

    const temProdutoNoCarrinho = (item) => {
        return props.carrinho[getIdProduto(item)];
    }

    const getQtdCarrinhoItem = (item) => {

        if (props.carrinho[getIdProduto(item)]) {
            return props.carrinho[getIdProduto(item)].qtd;
        }

        return 0;
    }



    const visualizarCarrinho = function () {
        setVerCarrinho(1);

        if (comanda == 0) {
            //alert(Comanda)
            setVisibleModalFormaPagamento(true);

            verificaFreteFixo();

            if (localStorage.getItem("distancia")) {
                var distancia = localStorage.getItem("distancia");
                distancia = parseFloat(distancia)
                calcularFreteVariavel(distancia)
            }
        }

    }

    const renderBotaoCarrinho = function () {
        let temItens = props.qtdItens && props.qtdItens > 0;

        if (temItens) {
            return (
                <Button className="whatsapp" style={{ zIndex: 10, background: "#e5b53e", color: "#000" }} data-wow-iteration="infinite" type=""
                    onClick={() => visualizarCarrinho()} >
                    <ShoppingCartOutlined style={{ fontSize: "24pt", marginLeft: "-30px" }} />
                    <span style={{ textAlign: "center" }}>VISUALIZAR MEU PEDIDO</span><br />
                    <span style={{ fontSize: "10pt" }}><strong style={{ fontSize: "14pt" }}>{props.qtdItens}</strong> Itens - <strong>Total R$ {parseFloat(props.valorTotalPedido).toFixed(2)}</strong></span><br />

                </Button>
            )
        }


    }

    const getDataAtual = function () {
        var data = new Date();

        // Guarda cada pedaço em uma variável
        var dia = data.getDate();           // 1-31
        var mes = data.getMonth();          // 0-11 (zero=janeiro)
        var ano4 = data.getFullYear();       // 4 dígitos

        if (["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].indexOf(dia.toString()) > 0) {
            dia = "0" + dia;
        }

        mes = mes + 1;

        if (["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].indexOf(mes.toString()) > 0) {
            mes = "0" + mes;
        }
        // Formata a data e a hora (note o mês + 1)
        var str_data = dia + '/' + mes + '/' + ano4;

        return str_data;
    }

    const getHoraAtual = function () {
        // Obtém a data/hora atual
        var data = new Date();

        var hora = data.getHours();          // 0-23
        var min = data.getMinutes();        // 0-59
        var seg = data.getSeconds();        // 0-59

        // Formata a data e a hora (note o mês + 1)
        var str_hora = hora + ':' + min + ':' + seg;

        // Mostra o resultado
        return str_hora;
    }

    const getDataHoraAtual = function () {
        // Obtém a data/hora atual
        var data = new Date();

        // Guarda cada pedaço em uma variável
        var dia = data.getDate();           // 1-31
        var dia_sem = data.getDay();            // 0-6 (zero=domingo)
        var mes = data.getMonth();          // 0-11 (zero=janeiro)
        var ano2 = data.getYear();           // 2 dígitos
        var ano4 = data.getFullYear();       // 4 dígitos
        var hora = data.getHours();          // 0-23
        var min = data.getMinutes();        // 0-59
        var seg = data.getSeconds();        // 0-59
        var mseg = data.getMilliseconds();   // 0-999
        var tz = data.getTimezoneOffset(); // em minutos

        // Formata a data e a hora (note o mês + 1)
        var str_data = dia + '/' + (mes + 1) + '/' + ano4;
        var str_hora = hora + ':' + min + ':' + seg;

        // Mostra o resultado
        return str_data + ' às ' + str_hora;
    }

    const pularLinha = () => {
        return "%0a";
    }

    const enviarWhatsapp = (idPedido) => {

        var txt = getDataHoraAtual();
        txt += pularLinha();
        txt += "No Pedido: %23" + idPedido;
        txt += pularLinha();
        txt += "Gostaria de fazer o pedido abaixo:";
        txt += pularLinha();

        let keys = Object.keys(props.carrinho);

        keys.forEach(key => {
            let item = props.carrinho[key];
            txt += pularLinha();
            txt += pularLinha();
            txt += "- " + item.qtd + "x " + item.nome + " _(R$" + item.preco + ")_ *R$" + parseFloat(item.preco * item.qtd).toFixed(2) + "*";

            if (item.radiosSelecionados) {
                Object.keys(item.radiosSelecionados).map(function (titulo) {
                    var objRadio = JSON.parse(item.radiosSelecionados[titulo]);
                    txt += pularLinha();
                    txt += "*" + titulo + "*: " + objRadio.nome;
                    if (!objRadio.substituirValorOriginal) {
                        txt += "* - R$ " + parseFloat(JSON.parse(item.radiosSelecionados[titulo]).valor).toFixed(2)
                    }
                    return "";
                })
            }

            if (item.checkboxesSelecionados) {
                Object.keys(item.checkboxesSelecionados).map(function (titulo) {
                    item.checkboxesSelecionados[titulo].map(function (strObj) {

                        var checkbox = JSON.parse(strObj);
                        txt += pularLinha();
                        txt += "*" + item.qtd + "x* ";
                        txt += "*" + titulo + "*: " + checkbox.nome;
                        txt += "* - R$ " + parseFloat(item.qtd * JSON.parse(strObj).valor).toFixed(2)
                        return "";
                    })
                    return "";
                })
            }

            if (item.adicionalObs) {
                txt += pularLinha();
                txt += "-- OBS: " + item.adicionalObs;
            }

        })

        txt += "%0a%0aFORMA DE PAGAMENTO: *" + tipoPagamento + "*";

        if (vaiRetirarNoLocal === "nao" && !props.configuracoes.somenteBalcao) {
            txt += pularLinha();
            txt += pularLinha();
            txt += "*ENDEREÇO DE ENTREGA:*"
            if (endereco.nomeDestinatario !== "") {
                txt += pularLinha();
                txt += "Entregar para: " + endereco.nomeDestinatario
            };
            if (telefoneDestinatario !== "" && telefoneDestinatario) {
                txt += pularLinha();
                txt += "Telefone: " + telefoneDestinatario
            };
            if (endereco.cep !== "") {
                txt += pularLinha();
                txt += "Cep: " + endereco.cep
            };
            if (endereco.logradouro !== "") {
                txt += pularLinha();
                txt += "Rua/Avenida: " + endereco.logradouro
            };
            if (endereco.numero !== "") {
                txt += pularLinha();
                txt += "Numero: " + endereco.numero
            };
            if (endereco.bairro !== "") {
                txt += pularLinha();
                txt += "Bairro: " + endereco.bairro
            };
            if (endereco.cidade !== "") {
                txt += pularLinha();
                txt += "Cidade: " + endereco.cidade + " - " + endereco.estado
            };
            if (endereco.complemento !== "") {
                txt += pularLinha();
                txt += "Complemento: " + endereco.complemento
            };
            if (endereco.distancia) {
                txt += pularLinha();
                txt += "Distancia Aproximada de : " + endereco.distancia + "Km"
            };


        } else {
            if (endereco.nomeDestinatario !== "") {
                txt += pularLinha();
                txt += "Entregar para: " + endereco.nomeDestinatario
            };
            txt += pularLinha();
            txt += "*O pedido será retirado no local.*"
        }

        if (props.configuracoes.hasOwnProperty("camposPersonalizados")) {
            var campos = props.configuracoes.camposPersonalizados;
            if (campos.length > 0) {
                campos.map(function (campo) {
                    if (campo.value !== "") {
                        txt += pularLinha();
                        txt += campo.label + ": ";
                        txt += campo.value;
                    }
                })
            }
        }

        if (endereco.dataDeEntrega) {
            txt += pularLinha();
            txt += pularLinha();
            txt += "ENCOMENDA PARA O DIA: *" + endereco.dataDeEntrega + "*";
            if (endereco.horarioDeEntregaHora) {
                txt += " as " + endereco.horarioDeEntregaHora;
            }
            if (endereco.horarioDeEntregaMinuto) {
                txt += ":" + endereco.horarioDeEntregaMinuto;
            }
        }

        if (valorFrete && vaiRetirarNoLocal == "nao") {
            var total = parseFloat(valorFrete) + parseFloat(props.valorTotalPedido);
            txt += pularLinha();
            txt += pularLinha();
            txt += "SUBTOTAL *R$" + parseFloat(props.valorTotalPedido).toFixed(2) + "*";
            txt += pularLinha();
            txt += pularLinha();
            if (valorFrete == 0) {
                txt += "FRETE *À CALCULAR*";
            } else {
                txt += "FRETE *R$" + parseFloat(valorFrete).toFixed(2) + "*";
            }
            txt += pularLinha();
            txt += pularLinha();
            txt += "*TOTAL PEDIDO R$" + total.toFixed(2) + "*";
        } else {
            txt += pularLinha();
            txt += pularLinha();
            txt += "*VALOR PEDIDO R$" + parseFloat(props.valorTotalPedido).toFixed(2) + "*";
        }


        //verifica se tem que pedir troco
        if (tipoPagamento === "Dinheiro" && valorDeTroco > 0) {
            txt += pularLinha();
            txt += pularLinha();
            txt += "TROCO PARA *R$" + trocoPara + "*";
        }



        txt += pularLinha();
        txt += pularLinha();
        txt += "Obrigado!"

        var isMobile = false; //initiate as false
        // device detection
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0, 4))) {
            isMobile = true;
        }
        if (isMobile) {
            window.location.href = "whatsapp://send?phone=" + props.configuracoes.telefone + "&text=" + txt;
        } else {
            window.location.href = "https://web.whatsapp.com/send?phone=" + props.configuracoes.telefone + "&text=" + txt;
        }
    }

    const salvarPedido = async function () {

        let keys = Object.keys(props.carrinho);
        var carrinho = [];
        keys.forEach(key => {
            carrinho.push(props.carrinho[key]);
        });

        try {
            var stringFormaPagamento = tipoPagamento;
            stringFormaPagamento = stringFormaPagamento.normalize('NFD').replace(/[\u0300-\u036f]/g, "");//sem acento
            stringFormaPagamento = stringFormaPagamento.replaceAll(" ", "_").replace('""', "").toUpperCase();//com underline e maiuscula

            var param = {
                idEmpresa: JSON.stringify(props.idEmpresa),
                carrinho: JSON.stringify(carrinho),
                endereco: JSON.stringify(endereco),
                camposPersonalizados: JSON.stringify(props.configuracoes.camposPersonalizados),
                cliente: JSON.stringify(cliente),
                frete: parseFloat(valorFrete, 2),
                formaPagamento: stringFormaPagamento,
                totalPedido: props.valorTotalPedido,
                troco: JSON.stringify({ trocoPara: trocoPara, valorDeTroco: trocoPara }),
                comanda: comanda,
                mesa: (localStorage.getItem("mesa") > 0) ? localStorage.getItem("mesa") : 0
            }

            var url = "salvarPedido";
            var retorno = await axiosPost(url, param);
            return retorno;
        } catch (error) {
            console.log(error)
        }
    }

    const verificaValorPedidoMinimo = function () {
        try {
            //se for ter retirada no balcao, nao precisa verificar pedido minimo, entao já retorna true
            if (vaiRetirarNoLocal === "sim") {
                return true;
            }

            if (props.configuracoes.valorPedidoMinimo) {
                if (parseFloat(props.valorTotalPedido) < parseFloat(props.configuracoes.valorPedidoMinimo)) {
                    alert("Desculpe, o valor minimo para realizar o pedido é de " + props.configuracoes.valorPedidoMinimo);
                    return false;
                }
            }
        } catch (e) {
            return true;
        }
        return true;
    }

    const preEnvio = function () {
        if (comanda == 0) {
            calculaDistancia();
        }
    }

    const validarEnviarPedido = async function () {

        if (comanda != 0) {
            var retornoCodigo = await verificaCodValidacaoLocalStorage();
            if (retornoCodigo == false) {
                return false;
            }
        }

        if (!tipoPagamento && comanda == 0) {
            setVisibleModalFormaPagamento(true);
            return false;
        }



        /* if (endereco.cep === "" && vaiRetirarNoLocal === "nao" && !props.configuracoes.somenteBalcao) {
             setState({ visibleModalFormEntrega: true })
             return false;
         }*/

        if (verificaValorPedidoMinimo() == false) {
            return false;
        }

        if (props.configuracoes.opcaoEncomendar == "sim") {
            if (!validarDataDeEntrega()) {
                alert("Data ou Horário de agendamento em desacordo com as regras!!! confira as regras na pagina inicial para fazer esse pedido.")
                return false;
            }
        }

        return true;
    }


    const enviarPedido = async function () {
        setLoading(true);
        preEnvio();
        if (await validarEnviarPedido()) {
            const retorno = await salvarPedido();
            if (comanda == 0) {
                enviarWhatsapp(retorno.id);
            } else {
                if (alert("Obrigado, agora é só aguardar seu pedido.")) { } else window.location.reload();
            }
        }
        setLoading(false);
    }

    const renderBotaoEnviarPedido = function () {
        var total = parseFloat(props.valorTotalPedido);
        if (valorFrete && vaiRetirarNoLocal == "nao") {
            total = parseFloat(valorFrete) + parseFloat(props.valorTotalPedido);
        }

        return (
            <Button loading={loading} className="whatsapp" style={{ zIndex: 10, background: "#e5b53e", borderColor: "#e5b53e", color: "#000" }} data-wow-iteration="infinite" onClick={() => enviarPedido()} >
                <ShoppingCartOutlined style={{ fontSize: "24pt", marginTop: "-10px" }} />
                <span style={{ textAlign: "center" }}>ENVIAR MEU PEDIDO</span> <br />


                <span style={{ fontSize: "10pt" }}><strong style={{ fontSize: "14pt" }}>{props.qtdItens}</strong> Itens - <strong>Total R$ {total.toFixed(2)}</strong></span><br />
            </Button>
        )
    }

    const verificaCarrinhoEstaVazio = function () {
        let keys = Object.keys(props.carrinho);
        return keys.length === 0;
    }

    const axiosGetCep = async function (enderecoPesquisa) {
        var ret = "";
        var url = "https://viacep.com.br/ws/" + enderecoPesquisa + "/json/";
        await axios.get(url).then(function (retorno) {
            ret = retorno.data;
        })
        return ret;
    }



    const axiosPost = async function (url, param) {
        var urlApi = await getUrlApiNucardapio();
        var retorno = await axios.post(urlApi + url, param);
        return retorno.data;
    }

    const getCheckboxButton = async function () {
        var idItemSelecionado = itemSelecionado.id;
        var idEmpresa = props.idEmpresa;
        var titulo = itemSelecionado.titulo;

        var urlApi = await getUrlApiNucardapio();
        axios.get(urlApi + "getAdicionalCardapioX?idCardapio=" + idItemSelecionado + "&categoria=" + titulo + "&idEmpresa=" + idEmpresa).then(function (retorno) {
            setAdicionais(retorno.data);
        })
    }

    const getRadiosButton = async function () {
        var idItemSelecionado = itemSelecionado.id;
        var idEmpresa = props.idEmpresa;
        var titulo = itemSelecionado.titulo;
        var url = "getRadios?idCardapio=" + idItemSelecionado + "&categoria=" + titulo + "&idEmpresa=" + idEmpresa;
        var retorno = await axiosGet(url);

        setRadios(retorno);
    }

    const montaTelaModalDeAdicionaisDoItem = function () {
        getCheckboxButton();
        getRadiosButton();
    }

    const openModalAdicionalDoItem = function (item) {
        limparRadiosSelecionado();
        limparCheckboxesSelecionado();

        setAdicionalChecked([])

        var multiplicadorQtd = 1;

        if (item.hasOwnProperty("configuracoes")) {
            if (item.configuracoes) {
                var config = JSON.parse(item.configuracoes);
                if (config.multiplicadorQtd) {
                    multiplicadorQtd = parseFloat(config.multiplicadorQtd);
                }
            }
        }

        setQtdTempAdicional(multiplicadorQtd)

        item.totalItem = item.preco;

        setItemSelecionado(JSON.parse(JSON.stringify(item)));

    }

    const cadastraItemNoPedidoComAdicional = e => {
        var novoItemSelecionado = JSON.parse(JSON.stringify(itemSelecionado));
        novoItemSelecionado.qtdAdicional = qtdTempAdicional;
        novoItemSelecionado.radiosSelecionados = radiosSelecionados;
        novoItemSelecionado.checkboxesSelecionados = checkboxesSelecionados;

        setVisible(false);

        addCarrinho(novoItemSelecionado);
    };

    const handleCancel = e => {
        setVisible(false);
    };

    const calcularTotalItemSelecionado = function () {
        var totalAdicional = 0;
        var totalRadios = 0;
        var novoitemSelecionado = itemSelecionado;

        if (checkboxesSelecionados) {

            Object.keys(checkboxesSelecionados).map(function (titulo) {
                checkboxesSelecionados[titulo].map(function (obj) {
                    let objRadio = JSON.parse(obj);
                    let valor = parseFloat(objRadio.valor);
                    totalAdicional += parseFloat(valor);
                    return "";
                })
                return "";
            })
        }

        if (itemSelecionado.radiosSelecionados) {

            Object.keys(itemSelecionado.radiosSelecionados).map(function (titulo) {
                let objRadio = JSON.parse(itemSelecionado.radiosSelecionados[titulo]);
                let valor = parseFloat(objRadio.valor)
                if (objRadio.substituirValorOriginal) {
                    valor -= itemSelecionado.preco;//subtrai o valor do produto, pois vai substituir, por exemplo pizza grande substitui o valor original do produto
                }
                totalRadios += parseFloat(valor);
                return "";
            })
        }

        novoitemSelecionado.totalItem = parseFloat(totalRadios) + parseFloat(totalAdicional) + parseFloat(itemSelecionado.preco);
        setItemSelecionado(JSON.parse(JSON.stringify(novoitemSelecionado)));
    }

    const changeCheckbox = function (e, titulo) {
        var tamanho = e.length;

        try {
            if (itemSelecionado.configuracoes) {
                //valida se nao esta no limite dos checkbox selecionados
                var configuracoes = JSON.parse(itemSelecionado.configuracoes);

                var maximoSelecaoCheckbox = configuracoes.maximoSelecaoCheckbox;
                var maximo = "";
                var configMaximoSelecaoTitulo = "";
                var mensagem = "";
                var exibeMensagem = false;

                if (maximoSelecaoCheckbox instanceof Array) {
                    maximoSelecaoCheckbox.map(function (obj, index) {
                        maximo = obj.maximo;
                        configMaximoSelecaoTitulo = obj.titulo

                        if (maximo) {
                            if (tamanho > maximo && titulo === configMaximoSelecaoTitulo) {
                                mensagem += "Escolha no máximo " + maximo + " (" + obj.titulo + ")\n";
                                exibeMensagem = true;
                            }
                        }
                    })
                } else {
                    maximo = maximoSelecaoCheckbox.maximo;
                    configMaximoSelecaoTitulo = maximoSelecaoCheckbox.titulo

                    if (maximo) {
                        if (tamanho > maximo && titulo === configMaximoSelecaoTitulo) {
                            mensagem = "Escolha no máximo " + maximo;
                            exibeMensagem = true;
                        }
                    }
                }

                if (exibeMensagem) {
                    message.info(mensagem);
                    return;
                }
            }
        } catch (error) {
            console.log(error)
        }

        //se estiver tudo ok checka o checkbox
        if (tamanho > 0) {
            checkboxesSelecionados[titulo] = e;
        } else {
            checkboxesSelecionados[titulo] = []
        }
        calcularTotalItemSelecionado();
    }

    const getRadioButtonFilhos = async function (idRadioPai) {
        var url = "getRadios?idRadioPai=" + idRadioPai + "&idEmpresa=" + props.idEmpresa;
        var radiosFilhoAux = await axiosGet(url);
        var stateRadiosFilho = JSON.parse(JSON.stringify(radiosFilho));
        stateRadiosFilho[idRadioPai] = radiosFilhoAux;

        if (radiosFilhoAux.length > 0) {
            setRadiosFilho(stateRadiosFilho)
        } else {
            console.log("radio pai vazio")
        }
    }

    const changeRadioButton = function (e) {
        console.log("change", e)
        var novoItem = Object.assign(itemSelecionado);
        var valor = e.target.value;
        var radiosSelecionadosAux = Object.assign(radiosSelecionados);


        var optionSelecionado = JSON.parse(valor);
        var nomeObjeto = optionSelecionado.titulo;

        radiosSelecionadosAux[nomeObjeto] = valor;

        setRadiosSelecionados(radiosSelecionadosAux);

        //radiosSelecionadosAux = JSON.stringify(radiosSelecionadosAux);
        novoItem.radiosSelecionados = radiosSelecionadosAux;
        setItemSelecionado(novoItem);

        calcularTotalItemSelecionado();

        if (!optionSelecionado.idRadioPai) {//carrega somente se nao for um radio button filho
            getRadioButtonFilhos(optionSelecionado.id);
        }
    }

    const changeTrocoPara = e => {
        var trocoPara = e.target.value;
        var valorDeTroco = trocoPara - props.valorTotalPedido;

        setTrocoPara(trocoPara);
        setValorDeTroco(valorDeTroco);
    }

    const renderFormaPagamento = () => {
        var arrayMeioPagamentos = props.configuracoes.meioPagamentos;

        return <div>
            <Form.Item>
                <span>(Por favor, selecione a forma de pagamento abaixo.)</span><br />
                <Select
                    style={{ width: 200 }}
                    showSearch="false"
                    placeholder="Selecione o tipo de pagamento."
                    optionFilterProp="children"
                    onChange={((e) => { setTipoPagamento(e) })}
                    onFocus={(() => { })}
                    onBlur={(() => { })}
                    onSearch={(() => { })}
                    defaultValue={tipoPagamento}
                    value={tipoPagamento}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Select.Option value="">SELECIONE</Select.Option>
                    {
                        arrayMeioPagamentos.map(function (x, key) {
                            var valor = x.nome
                            return <Select.Option key={key} value={valor}>{valor}</Select.Option>
                        })
                    }


                </Select>
            </Form.Item>
            {
                tipoPagamento === "Dinheiro" ?
                    <div className="divTroco">
                        <Form.Item>
                            <span>Vai precisar de TROCO?</span><br />
                            <Radio.Group defaultValue={vaiPrecisarTroco} buttonStyle="solid">
                                <Radio.Button value="sim" onChange={(e) => setVaiPrecisarTroco(e.target.value)}>SIM</Radio.Button>
                                <Radio.Button value="nao" onChange={(e) => setVaiPrecisarTroco(e.target.value)}>NÃO</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        {(vaiPrecisarTroco === "sim") ?
                            <Form.Item>
                                <span>Vou precisar de troco para R$ </span><Input value={trocoPara} onChange={changeTrocoPara} type="number" />
                                {valorDeTroco > 0 ?
                                    "ok! vamos levar até você R$ " + valorDeTroco.toFixed(2) + " de troco."
                                    : '(valor preenchido deve ser maior que o valor total do pedido)'
                                }
                            </Form.Item>
                            : ""
                        }
                    </div>
                    : ""
            }
        </div>
    }

    const validarDataDeEntrega = () => {


        var retorno = false;
        if (!props.configuracoes.hasOwnProperty("datasPermitidasEncomendar")) {//entao todas as datas sao permitidas
            setDataEncomendaEhValida(true);
            return true;
        }

        var horas = endereco.horarioDeEntregaHora;
        var minutos = endereco.horarioDeEntregaMinuto;

        if (!horas) {
            alert("Por favor, informe o horario.")
        }
        if (!minutos) {
            minutos = "00"
        }

        var horaEscolhida = horas + ":" + minutos + ":" + "00";
        var dataHoraEscolhida = new Date(endereco.dataDeEntrega + " " + horaEscolhida).getTime();
        var arrayDatasPermitidas = props.configuracoes.datasPermitidasEncomendar;

        //somente as datas do array em configuracoes da empresa são permitidas
        arrayDatasPermitidas.forEach(function (x) {
            var dataDaVezDe = x.dataHoraDe;
            dataDaVezDe = new Date(dataDaVezDe).getTime();
            var dataDaVezAte = x.dataHoraAte;
            dataDaVezAte = new Date(dataDaVezAte).getTime();

            if (parseFloat(dataHoraEscolhida) >= parseFloat(dataDaVezDe) && parseFloat(dataHoraEscolhida) <= parseFloat(dataDaVezAte)) {
                setDataEncomendaEhValida(true);
                retorno = true;
            }

        })

        if (retorno == false) {
            setVisibleModalFormEntrega(true);
        }

        return retorno;
    }

    const renderFormEncomendar = function () {
        return (
            <>
                <Form.Item>
                    <h1>Encomendar para qual data e hora?</h1>
                </Form.Item>
                <Form.Item label="Data">
                    <Input name="dataDeEntrega" value={endereco.dataDeEntrega} onChange={onChangeInput} type="date" />
                </Form.Item>
                <Form.Item label="Horario">
                    <Input name="horarioDeEntregaHora" style={{ width: "100px" }} value={endereco.horarioDeEntregaHora} onChange={onChangeInput} maxlenght="2" type="number" /> Horas&nbsp;
                    <Input name="horarioDeEntregaMinuto" style={{ width: "100px" }} value={endereco.horarioDeEntregaMinuto} onChange={onChangeInput} maxlenght="2" type="number" /> Minutos
                </Form.Item>
            </>
        )
    }

    const onChangeInputCamposPersonalizados = (campoDaVez, e) => {
        var campos = props.configuracoes.camposPersonalizados;
        var valor = e.target.value;

        campos.map((campo, index) => {
            if (campo.id == campoDaVez.id) {
                campos[index].value = valor;
            }
        })
    }

    const mostrarCamposPersonalizados = function () {
        try {
            var htmlCamposPersonalizados = "";
            var camposPersonalizados = props.configuracoes.camposPersonalizados;
            camposPersonalizados.map((campo) => {
                htmlCamposPersonalizados = <>
                    {htmlCamposPersonalizados}<Form.Item label={campo.label} name={campo.id}>
                        <Input name={campo.id} placeholder={campo.placeholder} onChange={(e) => onChangeInputCamposPersonalizados(campo, e)} />
                    </Form.Item></>
            })
            return htmlCamposPersonalizados
        } catch (e) {

        }
    }

    const getZonasEmitirNotas = async () => {
        var url = await getUrlApiEmitirNotas();
        var retorno = await axios.get(url + "/zonas-frete");
        console.log("retorno.data", retorno.data)
        return retorno.data;
    }


    const renderFormRetiraPedido = function () {
        return (<Form layout="vertical">
            {
                (props.configuracoes.opcaoEncomendar === "sim") ? renderFormEncomendar() : ""
            }
            {(!props.configuracoes.somenteDelivery && !props.configuracoes.somenteBalcao) ?

                <Form.Item>
                    <h1>Vai retirar o pedido no local?</h1>
                    <Radio.Group defaultValue={vaiRetirarNoLocal} buttonStyle="solid">
                        <Radio.Button value="sim" onChange={(e) => setVaiRetirarNoLocal(e.target.value)}>SIM</Radio.Button>
                        <Radio.Button value="nao" onChange={(e) => setVaiRetirarNoLocal(e.target.value)}>NÃO</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                : ""
            }
            {
                (vaiRetirarNoLocal === "sim" || props.configuracoes.somenteBalcao) ?
                    <div>
                        O pedido será retirado no local.<br />
                        <Form.Item label="Qual seu nome?">
                            <Input name="nomeDestinatario" value={endereco.nomeDestinatario} onChange={onChangeInput} />
                        </Form.Item>

                    </div>

                    :
                    <div>
                        <Form.Item label="Qual seu nome?">
                            <Input name="nomeDestinatario" value={endereco.nomeDestinatario} onChange={onChangeInput} />
                        </Form.Item>
                        <Form.Item
                            rules={[{ required: true, message: 'Por favor insira o telefone corretamente.' }, { type: 'string', min: 15 }]}
                            label="Qual seu telefone?">
                            <IMaskInput mask={"(00) 00000-0000"} class="ant-input" name="telefoneDestinatario" value={telefoneDestinatario} onChange={onChangeInput} />
                        </Form.Item>
                        <Form.Item label="Cep">
                            <Input name="cep" value={endereco.cep} onChange={onChangeCep} onBlur={(e) => onBlurCep(e)} />
                        </Form.Item>
                        <Form.Item label="Numero">
                            <Input name="numero" value={endereco.numero} onChange={onChangeInput} />
                        </Form.Item>
                        <Form.Item label="Complemento">
                            <Input name="complemento" value={endereco.complemento} onChange={onChangeInput} />
                        </Form.Item>
                        <FormItemBairro handleChangeBairro={handleChangeBairro} tipoFrete={props.configuracoes.tipoFrete} getZonasEmitirNotas={getZonasEmitirNotas} bairro={endereco.bairro} onChangeInput={onChangeInput}></FormItemBairro>
                        <Form.Item label="Rua">
                            <Input name="logradouro" value={endereco.logradouro} onChange={onChangeInput} />
                        </Form.Item>
                        <Form.Item label="Estado">
                            <Input name="estado" value={endereco.estado} onChange={onChangeInput} />
                        </Form.Item>
                        <Form.Item label="Cidade">
                            <Input name="cidade" value={endereco.cidade} onChange={onChangeInput} />
                        </Form.Item>


                    </div>
            }
            {
                mostrarCamposPersonalizados()
            }
        </Form>)
    }

    const onChangeCep = e => {
        //var endereco = endereco;
        setEndereco({ ...endereco, cep: e.target.value })
    }

    const onChangeInput = e => {
        //var endereco = endereco;

        switch (e.target.name) {
            case "dataDeEntrega": setEndereco({ ...endereco, dataDeEntrega: e.target.value });
                break;
            case "horarioDeEntregaHora": setEndereco({ ...endereco, horarioDeEntregaHora: e.target.value });
                break;
            case "horarioDeEntregaMinuto": setEndereco({ ...endereco, horarioDeEntregaMinuto: e.target.value });
                break;
            case "nomeDestinatario": setEndereco({ ...endereco, nomeDestinatario: e.target.value });
                break;
            case "telefoneDestinatario": setTelefoneDestinatario(e.target.value);
                break;
            case "logradouro": setEndereco({ ...endereco, logradouro: e.target.value });
                break;
            case "estado": setEndereco({ ...endereco, estado: e.target.value });
                break;
            case "cidade": setEndereco({ ...endereco, cidade: e.target.value });
                break;
            case "numero": setEndereco({ ...endereco, numero: e.target.value });
                break;
            case "bairro": setEndereco({ ...endereco, bairro: e.target.value });
                break;
            case "complemento": setEndereco({ ...endereco, complemento: e.target.value });
                break;
            default: return;
        }
    }

    const calcula_distancia_e_salvar = async () => {
        const distancia = await calculaDistancia();
        await salvarCliente();
        setVisibleModalFormEntrega(false);
    }

    const salvarCliente = async () => {
        var uuidEmpresa = window.localStorage.getItem("uuidEmpresa");
        uuidEmpresa = uuidEmpresa.replace(/['"]+/g, '');

        const param = {
            endereco: JSON.stringify(endereco),
            nome: endereco.nomeDestinatario,
            telefone: telefoneDestinatario,
            uuidEmpresa: uuidEmpresa
        };//no endereco tem outros campos tambem, como nome e telefone.
        const url = "salvarCliente";
        var retorno = await axiosPost(url, param);
        setCliente(retorno);
    }

    const calculaDistancia = async function () {
        if (props.configuracoes.hasOwnProperty("somenteBalcao")) {
            if (parseFloat(props.configuracoes.somenteBalcao) == 1) {
                return;
            }
        }

        //var endereco = endereco;
        var origem = props.configuracoes.endereco.logradouro + ". " + props.configuracoes.endereco.cidade + " - " + props.configuracoes.endereco.estado + ". CEP " + props.configuracoes.endereco.cep;
        var destino = endereco.logradouro + ", " + endereco.numero + ", " + endereco.bairro + "." + endereco.cidade + " - " + endereco.estado + " CEP " + endereco.cep;

        if (origem.length > 5 && destino.length > 5) {
            var param = { origem: origem, destino: destino }
            var url = "calcDistancia";
            var retorno = await axiosPost(url, param);

            var distancia = retorno.distancia
            distancia = parseFloat(distancia);
            distancia = distancia.toFixed(2)

            localStorage.setItem("distancia", distancia);//salvo para recarrecar o valor do frete variavel ao iniciar
            calcularFreteVariavel(distancia);

            return distancia;
        } else {
            return 0;
        }
    }

    const calcularFreteVariavel = function (distancia) {
        if (props.configuracoes.tipoFrete && props.configuracoes.tipoFrete == "porBairro") {
            return;
        }

        if (!props.configuracoes.valorFreteFixo) {
            var valorDoFreteVariavel = 0;
            if (props.configuracoes.freteVariavel) {
                props.configuracoes.freteVariavel.map(function (x) {
                    if (parseFloat(x.kmDe) <= parseFloat(distancia) && parseFloat(distancia) <= parseFloat(x.kmAte)) {
                        console.log("distancia", distancia);
                        console.log("kmDe", x.kmDe);
                        console.log("kmAte", x.kmAte);
                        valorDoFreteVariavel = x.valor;
                    }
                })
            }
            console.log('valorFreteVariavel', valorDoFreteVariavel)
            setValorFrete(valorDoFreteVariavel)
        }
    }

    const onBlurCep = async function (e) {
        try {
            var cep = e.target.value;
            if (props.configuracoes.endereco.cep) {
                var retorno = await axiosGetCep(cep);

                const c = retorno;

                setEndereco({
                    ...endereco,
                    logradouro: c.logradouro,
                    complemento: c.complemento,
                    bairro: c.bairro,
                    cidade: c.localidade,
                    estado: c.uf
                })
            }
        } catch (error) {
            console.log("error", error)
            //setEndereco({...endereco,enderecoNovo})
        }
    }

    const changeAdicionalObs = function (e) {
        //var itemSelecionado = itemSelecionado;
        setItemSelecionado({
            ...itemSelecionado,
            adicionalObs: e.target.value
        });
    }


    const renderRadiosFilho = function (objSelecionado) {

        if (!objSelecionado) {
            return;
        }
        console.log("objSelecionado", objSelecionado);

        var idPai = JSON.parse(objSelecionado).id;
        var radios = [];
        var tituloAux = "";
        var html = "";

        //var radiosSelecionados = radiosSelecionados;
        console.log(radiosFilho)
        Object.keys(radiosFilho).map(function (xid, index) {
            if (xid == idPai) {
                var xx = radiosFilho[xid];
                var tamanhoArray = xx.length - 1;
                xx.map(function (x, index) {


                    var label = <span key={index} style={{ width: "100%" }}>{x.nome}
                        {
                            (x.valor > 0) ?
                                <span key={index} className="colorRed">(+ R$ {parseFloat(x.valor).toFixed(2)})
                                    {(x.descricao) ? <br /> : ``}
                                    <span className="descricaoRadio" style={{ fontWeight: "normal" }}>{x.descricao}</span>
                                </span>

                                : ""

                        }
                    </span>

                    if ((x.titulo !== tituloAux && tituloAux !== "")) {
                        html = <div><br />{html}
                            <strong className="titleOpcoes" style={{ display: "block", width: "100%" }}>{tituloAux}</strong>
                            <Radio.Group value={radiosSelecionados[tituloAux]} onChange={(e) => changeRadioButton(e)} options={radios} />
                        </div>

                        radios = [];
                    }

                    radios.push({ label: label, value: JSON.stringify(x) });
                    tituloAux = x.titulo;

                    if (tamanhoArray === index) {

                        html = <div><br />{html}
                            <strong className="titleOpcoes" style={{ display: "block", width: "100%" }}>{tituloAux}</strong>
                            <Radio.Group value={radiosSelecionados[tituloAux]} onChange={(e) => changeRadioButton(e)} options={radios} />
                        </div>
                    }
                })
            }
            return;
        })
        console.log("html", html)
        return html;
    }

    const renderRadios = function () {
        var radiosAux = [];
        var tituloAux = "";
        var html = "";
        var tamanhoArray = radios.length - 1;
        /* var radiosSelecionados = radiosSelecionados;
        if(!radiosSelecionados){
            radiosSelecionados = {};
        
        } */

        radios.map(function (x, index) {
            var label = <span key={index} style={{ width: "100%" }}>{x.nome}
                {
                    (x.valor > 0) ?
                        <span key={index} className="colorRed">(+ R$ {parseFloat(x.valor).toFixed(2)})</span>
                        : ""

                }
            </span>

            if ((x.titulo !== tituloAux && tituloAux !== "")) {
                html = <div>{html}
                    <strong className="titleOpcoes" style={{ display: "block", width: "100%" }}>{tituloAux}</strong>
                    <Radio.Group value={radiosSelecionados[tituloAux]} onChange={(e) => changeRadioButton(e)} options={radiosAux} />

                    {renderRadiosFilho(radiosSelecionados[tituloAux])}
                    <br /><br />
                </div>

                radiosAux = [];
            }

            radiosAux.push({ label: label, value: JSON.stringify(x), checked: true });
            tituloAux = x.titulo;

            if (tamanhoArray === index) {
                html = <div>{html}
                    <strong className="titleOpcoes" style={{ display: "block", width: "100%" }}>{tituloAux}</strong>
                    <Radio.Group value={radiosSelecionados[tituloAux]} onChange={(e) => changeRadioButton(e)} options={radiosAux} />
                    {renderRadiosFilho(radiosSelecionados[tituloAux])}
                    <br /><br />
                </div>
            }

        })


        return html;
    }

    const renderCheckbox = function () {
        var checkboxes = [];
        var tituloAux = "";
        var html = "";
        var tamanhoArray = adicionais.length - 1;
        /* var checkboxesSelecionados = checkboxesSelecionados; */

        adicionais.map(function (x, index) {
            var label = <span key={index} style={{ width: "100%" }}>{x.nome}
                {(x.valor > 0) ? <span key={index} className="colorRed">(+ R$ {parseFloat(x.valor).toFixed(2)})</span> : ""}
            </span>

            if ((x.titulo !== tituloAux && tituloAux !== "")) {
                var t = tituloAux;
                html = <div>{html}
                    <strong className="titleOpcoes" style={{ display: "block", width: "100%" }}>{tituloAux}</strong>
                    <Checkbox.Group value={checkboxesSelecionados[tituloAux]} onChange={(e) => changeCheckbox(e, t)} options={checkboxes} />
                    <br /><br />
                </div>

                checkboxes = [];
            }

            checkboxes.push({ label: label, value: JSON.stringify(x), checked: true });
            tituloAux = x.titulo;

            if (tamanhoArray === index) {
                html = <div>{html}
                    <strong className="titleOpcoes" style={{ display: "block", width: "100%" }}>{tituloAux}</strong>
                    <Checkbox.Group value={checkboxesSelecionados[tituloAux]} onChange={(e) => changeCheckbox(e, tituloAux)} options={checkboxes} />
                </div>
            }

        })
        return html;
    }

    const renderLogoEBanner = () => {
        let logo, banner;
        if (props.configuracoes.logo.indexOf("http") === 0) {
            logo = props.configuracoes.logo;
        }
        else {
            logo = process.env.PUBLIC_URL + "/imagens/logos/" + props.configuracoes.logo
        }


        return <div>
            <Row>
                <Col span={24} >
                    <img className="logoEmpresa" alt="Logo Empresa" src={logo} />
                </Col>
            </Row>
            <Row>
                <Col span={24} >
                    <Banner prefixCls="bannerInicial" banners={props.banners}></Banner>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <TabFiltros filtros={props.filtros} />
                </Col>
            </Row>
        </div>
    }

    const renderMensagemHeaderCarrinho = () => {
        return <div>
            <Row>
                <Col span={24}>
                    <h1 style={{ marginTop: "100px", padding: "5% 5% 0% 5%", cursor: "pointer" }} onClick={() => setVerCarrinho(!verCarrinho)}><ArrowLeftOutlined /> Voltar</h1>
                </Col>
            </Row>
            <Row style={{ textAlign: "center", padding: "50px" }}>
                <Col span={24}>
                    <p><strong>Falta pouco para enviar seu pedido!</strong></p>
                    <p>Confirme os itens abaixo selecionados para o pedido, e clique no botão ENVIAR PEDIDO</p>
                </Col>
            </Row>
        </div>
    }

    const renderHeader = function () {
        return <div>
            <MenuPrincipal configuracoes={props.configuracoes} comanda={comanda} />
            {
                (!verCarrinho) ?
                    renderLogoEBanner()
                    :
                    renderMensagemHeaderCarrinho()
            }
        </div>
    }

    const renderModalFormaDePagamento = () => {

        return <Modal
            className="modalFormaPagamento"
            title="Qual a forma de Pagamento?"
            onCancel={() => setVisibleModalFormaPagamento(false)}
            visible={visibleModalFormaPagamento}
            footer={[
                (tipoPagamento !== "") ?
                    <div key="btn1">
                        {<Button key="submit" type="primary" onClick={() => { setVisibleModalFormaPagamento(false); setVisibleModalFormEntrega(true); }}>
                            OK, Vou Pagar com {tipoPagamento}
                        </Button>}
                    </div>

                    : <div key="btn2">{<Button disabled={true} type="primary">OK</Button>}</div>

            ]}
        >
            {renderFormaPagamento()}
        </Modal>
    }

    const validarCampos = () => {
        if (vaiRetirarNoLocal === "nao" && !props.configuracoes.somenteBalcao) {
            if (!telefoneDestinatario) {
                message.info("Por favor, digite o telefone corretamente com o DDD e o digito 9 antes do número.");
                return;
            }

            var telefone = telefoneDestinatario;
            telefone = telefone.replace("(", "");
            telefone = telefone.replace(")", "");
            telefone = telefone.replace("_", "");
            telefone = telefone.replace("-", "");
            telefone = telefone.replace(" ", "");
            if (telefone.length == 11 || telefone.length == 10) {
                calcula_distancia_e_salvar();
            } else {
                message.info("Por favor, digite o telefone corretamente com o DDD e o digito 9 antes do número.");
                document.getElementById("telefoneDestinatario").focus();
            }
        } else {
            limpaEndereco();
            calcula_distancia_e_salvar();
        }
    }

    const renderModalEnderecoDeEntrega = () => {
        return <Modal
            className="modalFormEntrega"
            
            title={(vaiRetirarNoLocal === "nao" && !props.configuracoes.somenteBalcao) ? "Entrega" : "Retirada"}
            onCancel={() => { calculaDistancia(); setVisibleModalFormEntrega(false); }}
            visible={visibleModalFormEntrega}
            footer={[
                (tipoPagamento !== "") ?
                    <div key={"btn1" + itemSelecionado.id}>
                        {<Button key="submit" type="primary" onClick={() => { validarCampos() }}>
                            OK, tudo certo!
                        </Button>}
                    </div>

                    : <div key={"btn2" + itemSelecionado.id}>{<Button key="submit" disabled={true} type="primary">OK</Button>}</div>

            ]}
        >
            {renderFormRetiraPedido()}
        </Modal>
    }

    const renderBannerProduto = function () {
        if (itemSelecionado.img) {
            console.log(itemSelecionado.img)
            return <Banner id="bannerImgProdutos" banners={JSON.parse(itemSelecionado.img)} dirProdutos={props.configuracoes.dirProdutos} />
        }
        return;
    }

    const removeCarrinhoComAdicional = function (itemSelecionado) {

        try {
            var multiplicadorQtd = 1;
            if (itemSelecionado && itemSelecionado.configuracoes) {
                var config = JSON.parse(itemSelecionado.configuracoes);
                if (config.multiplicadorQtd) {
                    multiplicadorQtd = parseFloat(config.multiplicadorQtd);
                }
            }

            if (parseFloat(qtdTempAdicional) === multiplicadorQtd) {
                setQtdTempAdicional(multiplicadorQtd)
            } else {
                setQtdTempAdicional(parseFloat(qtdTempAdicional) - multiplicadorQtd)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const addCarrinhoComAdicional = function (itemSelecionado) {

        try {
            var multiplicadorQtd = 1;
            if (itemSelecionado && itemSelecionado.configuracoes) {
                var config = JSON.parse(itemSelecionado.configuracoes);
                if (config.multiplicadorQtd) {
                    multiplicadorQtd = parseFloat(config.multiplicadorQtd);
                }
            }

            setQtdTempAdicional(parseFloat(qtdTempAdicional) + multiplicadorQtd)
        } catch (e) {
            console.log(e)
        }
    }

    const handleChangeBairro = (bairroSelecionado) => {
        //alert("ok")
        bairroSelecionado = JSON.parse(bairroSelecionado)
        setEndereco({ ...endereco, bairro: bairroSelecionado.nome });

        setValorFrete(parseFloat(bairroSelecionado.valor_frete))
    }

    const codigoEhValido = async () => {
        var objCodValidacao = await findOrCreateCodigoValidacao();
        objCodValidacao = objCodValidacao[0].codValidacao;

        if (objCodValidacao == codigoValidacao) {
            localStorage.setItem("codigoValidacao", codigoValidacao);
            localStorage.setItem("mesa", mesa);
            return true;
        } else {
            return false;
        }

    }

    const confirmarDadosPopupGarcom = async () => {
        setVisibleModalValidacaoQRCode(false)//esconde
        if (await codigoEhValido()) {
            enviarPedido();
            //notification.open({ message: 'Confirmado', description: 'Seja bem-vindo(a), pode fazer seu pedido.' })
        } else {
            setVisibleModalValidacaoQRCode(true)//mostra input codigo
            notification.open({ message: 'Código Inválido', description: 'Verifique o código e tente novamente.' })
        }
    }

    const renderModalVerificarCodigoQRCode = function () {
        return <Modal
            className="modal"
            onOk={() => confirmarDadosPopupGarcom()}
            visible={visibleModalValidacaoQRCode}>
            <p>Por favor aguarde o Garçom trazer o código para poder realizar seus pedidos.</p>
            <Form.Item label="Peça o código ao garçom:">
                <Input placeholder='Digite código aqui' defaultValue={codigoValidacao} onChange={changeCodigoValidacao} ></Input>
            </Form.Item>
            <Form.Item label="Qual mesa você esta?">
                <Input placeholder='Digite código da mesa' defaultValue={mesa} onChange={changeMesa} ></Input>
            </Form.Item>
        </Modal>
    }


    const changeCodigoValidacao = (e) => {
        setCodigoValidacao(e.target.value);
    }

    const changeMesa = (e) => {
        setMesa(e.target.value);
    }

    const renderModalDetalharItemSelecionado = function () {

        return <Modal
            className="modal"
            title=""
            visible={visible}
            style={{ background: props.configuracoes.cor.primaria }}
            onOk={cadastraItemNoPedidoComAdicional}
            onCancel={handleCancel}
            footer={
                (!props.configuracoes.somenteVisualizacao) ?
                    [
                        <div key={itemSelecionado}>

                            <div className="btnAddCarrinho">
                                <Button className="addButton" style={{ borderColor: props.configuracoes.cor.primaria }} onClick={() => removeCarrinhoComAdicional(itemSelecionado)}><MinusOutlined /></Button>
                                <span className="qtdItem" >
                                    {qtdTempAdicional}
                                </span>
                                <Button className="addButton" style={{ borderColor: props.configuracoes.cor.primaria }} onClick={() => addCarrinhoComAdicional(itemSelecionado)}><PlusOutlined /></Button>
                            </div>
                            <br />

                            {<Button key="submit" type="primary" onClick={cadastraItemNoPedidoComAdicional}>
                                Adicionar R$ {parseFloat(itemSelecionado.totalItem).toFixed(2) * qtdTempAdicional}
                            </Button>}

                        </div>
                    ]

                    : []
            }
        >
            <form /* ref="form" */>
                {renderBannerProduto()}
                <h1>{itemSelecionado.nome}</h1>
                <p>{itemSelecionado.descricao}</p>

                {renderRadios()}

                {renderCheckbox()}
                <br />
                <br />
                <label>Algum comentário?</label>
                <TextArea value={itemSelecionado.adicionalObs} onChange={(e) => changeAdicionalObs(e)} placeholder="Ex: tirar a cebola, maionese à parte etc."></TextArea>
            </form>
        </Modal>
    }

    const renderModais = () => {
        return (
            <Layout>
                {renderModalFormaDePagamento()}
                {renderModalEnderecoDeEntrega()}
                {renderModalDetalharItemSelecionado()}
                {renderModalVerificarCodigoQRCode()}
            </Layout>
        )
    }

    const renderTelaDoCarrinho = function () {
        return <div className="carrinho bgBlack" style={{ paddingBottom: "70px" }}>
            <Row style={(comanda > 0) ? { display: "none" } : {}}>
                <Col span={24} style={{ border: "10px solid #F5F5F5", padding: "25px", textAlign: 'center' }}>
                    <h1>Qual a forma de pagamento?</h1>
                    {renderFormaPagamento()}
                </Col>
            </Row>

            <Row style={(comanda > 0) ? { display: "none" } : {}}>
                <Col span={24} style={{ border: "10px solid #F5F5F5", padding: "25px", textAlign: 'center', display: 'inline' }}>

                    {

                        <Row>
                            <Col sm={8}>
                                <h1>Destinatário</h1>

                                Entregar para: {endereco.nomeDestinatario} <br />
                                Telefone: {telefoneDestinatario} <br />
                                <br />
                            </Col>

                            {
                                (!props.configuracoes.somenteBalcao) ?
                                    <>
                                        <Col sm={8}>
                                            <h1>Endereço de Entrega</h1>

                                            Cep: {endereco.cep},
                                            Rua/Avenida: {endereco.logradouro},
                                            {endereco.numero}, <br />
                                            {endereco.bairro},
                                            {endereco.cidade + " - " + endereco.estado},
                                            Complemento: {endereco.complemento}<br />
                                            <br />

                                            <Button onClick={() => setVisibleModalFormEntrega(true)}>
                                                Endereço de Entrega
                                            </Button>
                                            <br />
                                        </Col>


                                        <Col sm={8}>
                                            <h1>Distancia</h1>

                                            <strong>Distancia Aproximada de:</strong> {localStorage.getItem("distancia")} Km

                                            <br />
                                            {(valorFrete == 0) ?

                                                <Alert style={{ margin: "10px" }} type="warning" message={"ENTREGA À CALCULAR"}></Alert>
                                                :
                                                <Alert style={{ margin: "10px" }} type="warning" message={"Taxa de Entrega: R$" + parseFloat(valorFrete).toFixed(2)}></Alert>
                                            }
                                        </Col>
                                    </> : ""
                            }
                        </Row>



                    }
                </Col>
            </Row>
            <PaginaCarrinho />

            {
                (verificaCarrinhoEstaVazio()) ?
                    <div style={{ padding: "25px" }}>
                        <p>Por favor clique no botão abaixo para visualizar o cardápio e fazer seu pedido.</p>
                        <Button className="addButton" style={{ borderColor: props.configuracoes.cor.primaria }} onClick={() => window.location.reload()}>VER CARDAPIO</Button>
                    </div>
                    : ""
            }

            {(verificaCarrinhoEstaVazio()) ? "" : renderBotaoEnviarPedido()}
        </div>
    }

    var returnLojaFechada = verificaSeLojaFechada();

    /*  if (returnLojaFechada) {
         return (
             <Layout className="paginaInicial">
 
                 {renderHeader()}
                 <br />
                 <Alert message={returnLojaFechada.mensagem} type="warning" showIcon />
             </Layout>
         )
     } */

    return (
        <Layout className="paginaInicial">
            <Empresa statusAtivo="1" />
            {(returnLojaFechada) ? <Alert message={returnLojaFechada.mensagem} type="warning" showIcon /> : ""}
            {renderHeader()}
            {(returnLojaFechada) ? <Alert message={returnLojaFechada.mensagem} type="warning" showIcon /> : ""}

            <Row className="paginaInicial">
                <Col span={24} className="margin5" style={{ marginTop: "0px" }}>
                    {

                        (verCarrinho) ?
                            renderTelaDoCarrinho()
                            :
                            <div className="cardapioProdutos bgBlack">
                                {renderBotaoCarrinho()}
                                {renderCardapio()}
                            </div>
                    }
                    <Row>
                        <br></br>

                    </Row>
                </Col>
            </Row>

            {renderModais()}
        </Layout>

    )



}


const mapDispatchToProps = dispatch =>
    bindActionCreators({ setCarrinho, setQtdItens, calcularTotalPedido, setFiltros }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaginaInicial);