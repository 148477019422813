import { SET_CARDAPIO } from '../actions/actionTypes';

const initialState = {
    cardapio: []
};

export const reducerCardapio = (state = initialState, action) => {
    switch (action.type) {
        case SET_CARDAPIO:
            return {
                cardapio: action.cardapio
            };
        default:
            return state;
    }
};