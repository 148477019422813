import React from 'react';
import { Layout, Tabs } from 'antd';
import './TabFiltros.css';

const { TabPane } = Tabs;

class TabFiltros extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        }

    }

    change = function(filtro){
        var all = document.getElementsByClassName('hideShowTabs');
        var classFiltro = document.getElementsByClassName(filtro.replace(" ",""));
        var i = 0;

        //mostra todos se filtro escolhido for todos
        if(filtro === 'TODOS'){
             for (i = 0; i < all.length; i ++) {
            all[i].style.display = 'block';
        }
        return;
        }


        //esconde todos
        for (i = 0; i < all.length; i ++) {
            all[i].style.display = 'none';
        }

        //mostra somente a classe selecionada
        for (i = 0; i < classFiltro.length; i ++) {
            classFiltro[i].style.display = 'block';
        }




    }

    render() {
       
        return (

            <Layout>
                <Tabs style={{"position": "relative","zIndex": "10"}} defaultActiveKey="1" onChange={(filtro) => this.change(filtro)}>
                    {
                        this.props.filtros.map(function (x) {
                            var objX = x;
                           return Object.keys(x).map(function (y) {
                                var filtro = objX[y];
                                if(filtro !== "ATENCAO") {
                                    return <TabPane tab={filtro} key={filtro} ></TabPane>
                                }
                                return "";
                            })

                        })
                    }
                </Tabs>
            </Layout>
        );
    }
}

export default TabFiltros;