import React from 'react';
import { Layout, Button, Input, Row, Col,  Badge } from 'antd';
import {TagsOutlined, RightOutlined, LeftOutlined} from '@ant-design/icons';
import './Pedidos.scss';
import axios from 'axios';
//FUNCOES
import { getUrlApiNucardapio } from '../../_environments/ConfigAPI'
import ModalPedidoX from '../../components/modalPedidoX/ModalPedidoX';
//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setVisibleModalX } from '../../actions';
import Empresa from '../../components/empresa/Empresa';

class Pedidos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pedidosEmAberto: [],
            pedidosPreparando: [],
            pedidosSaiuParaEntrega: [],
            valorFrete: 0,
            idEmpresa: 0,
            objSelecionado: { carrinho: "[]", endereco: "[]" }
        }
    }


    carregarPedidos = async function (idEmpresa, status) {
        var retornoNovo = [];
        var urlApi = await getUrlApiNucardapio();
        await axios.get(urlApi + "getPedidosEmpresaX?empresa=" + idEmpresa + "&status=" + status).then(function (retorno) {
            retorno.data.map(function (x) {
                //inserindo novos campos de controle no pedido
                x.endereco = JSON.parse(x.endereco);
                x.status = x.status || "em_aberto";
                x.frete = x.frete || 0;

                retornoNovo.push(x);
                return "";
            })
        });
        this.setState({ idEmpresa: idEmpresa });

        if (status == "em_aberto") {
            this.setState({ pedidosEmAberto: retornoNovo })
        }

        if (status == "preparando") {
            this.setState({ pedidosPreparando: retornoNovo })
        }

        if (status == "saiu_para_entrega") {
            this.setState({ pedidosSaiuParaEntrega: retornoNovo })
        }
        return retornoNovo;

    }

    imprimir = function (x) {
        this.setState({ objSelecionado: x })
        window.setTimeout(() => window.print(), 1500);
    }

    changeFrete = function (e, key) {
        console.log(this.state.pedidosEmAberto)
        var valor = e.target.value || 0;
        var pedidos = this.state.pedidosEmAberto;
        pedidos[key].frete = parseFloat(valor);
        console.log(pedidos[key])
        this.setState({ pedidos: pedidos });
    }

    renderPedido = function (pedidos, x, key) {
        var pedido = JSON.parse(x.carrinho);
        var retorno = [];
        var subtotal = 0;
        var total = 0;
        var self = this;

        pedido.map(function (item) {


            subtotal += item.totalItem;
            total = subtotal;

            console.log(pedidos[key])
            //soma o frete
            if (pedidos[key].frete) {
                total = subtotal + pedidos[key].frete;
            }

            retorno.push(<Row onClick={() => self.openModal(key)} key={`${key}`} className="carrinho">
                <Col key={x.id}>

                    <span>
                        <strong>{item.nome}</strong>
                        ({item.qtd}xR${parseFloat(item.preco).toFixed(2)} )
                    </span>
                    <span className="preco">
                        R$ {parseFloat(item.totalItem).toFixed(2)}
                    </span>

                </Col>
            </Row>)
            return "";
        })

        retorno.push(
            <Row key={key} style={{}}>
                <Col>
                    <div>
                        {/*<strong>SUBTOTAL</strong>: R$ {subtotal.toFixed(2)}<br />*/}
                        <div style={{ padding: "3px" }}>
                            <strong>FRETE</strong>: <span style={{ float: "right" }}>
                                R$ <Input style={{ display: "inline", width: "70px" }} onChange={(e) => this.changeFrete(e, key)} value={pedidos[key].frete} type="text" /></span>
                            <br />
                            <br />
                        </div>
                        <div onClick={() => self.openModal(key)}>
                            <Button style={{ float: "right", color: "#000", fontWeight: "bold", background: "#FFF", border: "none", fontSize: "12pt" }}><TagsOutlined /> R$ {total.toFixed(2)}</Button><br />
                        </div>
                    </div>

                    <ModalPedidoX x={x} total={total} id={`${key}`} />

                </Col>
            </Row>
        )

        return retorno.map(ret => {
            return ret;
        })
    }

    changeStatus = function (e, key) {
        var pedidos = this.state.pedidosEmAberto;
        pedidos[key].status = e;
        this.setState({ pedidos: pedidos })
    }

    salvarPedido = async function(x){
        var url = "salvarPedido"
        var self=this;
        x.endereco = JSON.stringify(x.endereco)
        var urlApi = await getUrlApiNucardapio();
        axios.post(urlApi + url,x).then(function(retorno){
            
            self.carregarPedidos(self.state.idEmpresa,"em_aberto")
            self.carregarPedidos(self.state.idEmpresa,"preparando")
            self.carregarPedidos(self.state.idEmpresa,"saiu_para_entrega")
        })
    }

    voltarStatusPedido = function(x){
        if(x.status == "em_aberto"){
            x.status = "em_aberto"
        }else if(x.status == "preparando"){
            x.status = "em_aberto"
        }else if(x.status == "saiu_para_entrega"){
            x.status = "preparando"
        }

        this.salvarPedido(x);
    }

    proximoStatusPedido = function(x){
        if(x.status == "em_aberto"){
            x.status = "preparando"
        }else if(x.status == "preparando"){
            x.status = "saiu_para_entrega"
        }

        this.salvarPedido(x);
    }

    renderCabecalhoPedido = function (x, key) {

        var nome = x.endereco.nomeDestinatario || "(nome não preenchido)"
        return <Row className="">
            <Col style={{ textAlign: "center" }} key={key + 2}>
            <Button onClick={() => this.voltarStatusPedido(x)} style={{ float: "left", fontSize: "12pt", margin: "10px" }}><LeftOutlined style={{ fontSize: "12pt" }} /></Button>
                <h1 style={{ display: "inline-block" }}><span style={{ color: "#666" }}>{nome}</span> </h1>
                <Button onClick={() => this.proximoStatusPedido(x)} style={{ float: "right", fontSize: "12pt", margin: "10px" }}><RightOutlined style={{ fontSize: "12pt" }}  /></Button>
            </Col>
        </Row>
    }

    openModal = function (key) {
        this.props.setVisibleModalX(`${key}`, true)
    }

    renderTodosPedidos = function (status) {
        var self = this;
        var pedidos = this.state.pedidosEmAberto;

        if (status == "preparando") {
            pedidos = this.state.pedidosPreparando
        }

        if (status == "saiu_para_entrega") {
            pedidos = this.state.pedidosSaiuParaEntrega
        }

        return pedidos.map(function (x, key) {
            return <Col
                className="pedidos"
                sm={24}
                key={key + x.id}
            >
                <div className="pedidosBorda">

                    <Row className="header" key={key + 1}>
                        <Col>
                            {self.renderCabecalhoPedido(x, key)}
                        </Col>
                    </Row>
                    <Row className="body">
                        <Col>
                            {self.renderPedido(pedidos,x, key)}
                        </Col>
                    </Row>

                    <Row className="footer">
                        <Col>

                        </Col>
                    </Row>
                </div>
            </Col>
        })
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.idEmpresa !== this.state.idEmpresa) {
            var emAberto = this.carregarPedidos(nextProps.idEmpresa, "em_aberto");
            var preparando = this.carregarPedidos(nextProps.idEmpresa, "preparando");
            var saiuParaEntrega = this.carregarPedidos(nextProps.idEmpresa, "saiu_para_entrega");

        }
    }

    render() {
        var self = this;
        return (
            <Layout className="pgPedidos" key={1}>
                <Empresa statusAtivo="1" />

                <Layout className="no-print" key={2} >
                    <div className="headerPedidos">
                        <img alt="nucardapio" style={{ float: "left", height: "40px" }} src={process.env.PUBLIC_URL + "/imagens/logos/logo-nucardapio-rosa.png"}></img>
                    </div>

                    <Row className="marginTopo">
                        <Col sm={8}>
                            <div className="box">
                                <h1 className="titleBox cor1">AGUARDANDO PEDIDO</h1>
                                <div className="bodyBox">
                                    <Row>
                                        {
                                            this.renderTodosPedidos("em_aberto")
                                        }
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col sm={8}>
                            <div className="box">
                                <h1 className="titleBox cor2">PREPARANDO PEDIDO</h1>
                                <div className="bodyBox">
                                    <Row>
                                        {

                                            this.renderTodosPedidos("preparando")

                                        }
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col sm={8}>
                            <div className="box">
                                <h1 className="titleBox cor3">SAIU PARA ENTREGA</h1>
                                <div className="bodyBox">
                                    <Row>
                                        {

                                            this.renderTodosPedidos("saiu_para_entrega")

                                        }
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Layout>

            </Layout>
        )
    }
}

const mapStateToProps = store => ({
    idEmpresa: store.setConfiguracoes.idEmpresa
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setVisibleModalX }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Pedidos);