import { reducerCarrinho } from './reducerCarrinho';
import { reducerConfiguracoes } from './reducerConfiguracoes';
import { reducerBanners } from './reducerBanners';
import { reducerCardapio } from './reducerCardapio';
import { reducerPedido } from './reducerPedido';
import { reducerFiltros } from './reducerFiltros';
import { reducerModalX } from './reducerModalX';

import { combineReducers } from 'redux';

export const Reducers = combineReducers({
  setCarrinho: reducerCarrinho,
  setConfiguracoes: reducerConfiguracoes,
  setIdEmpresa: reducerConfiguracoes,
  setBanners: reducerBanners,
  setCardapio: reducerCardapio,
  calcularTotalPedido: reducerPedido,
  setFiltros: reducerFiltros,
  setVisibleModalX: reducerModalX
});