export default function getIdProduto(produto) {
    var idCarrinho = produto.id;
    var id = "i_" + idCarrinho;
    var idAdicionais = "";
    var idRadiosSelecionados = "";

    if (produto.radiosSelecionados) {

        Object.keys(produto.radiosSelecionados).map(function (titulo) {
            idRadiosSelecionados += "_r" + JSON.parse(produto.radiosSelecionados[titulo]).id
            return "";
        })
    }

    if (produto.checkboxesSelecionados) {
        Object.keys(produto.checkboxesSelecionados).map(function (xx) {
            produto.checkboxesSelecionados[xx].map(function (x) {
                idAdicionais += "_a" + JSON.parse(x).id;
                return "";
            })
            return "";
        })
    }

    return id + idAdicionais + idRadiosSelecionados;
}