import React from 'react';
import './App.scss';
import { Row, Col } from 'antd';
import { BrowserRouter, Switch} from 'react-router-dom'
import Rotas from './Rotas'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
    this.handler = this.handler.bind(this);
  }

  handler = function (obj) {
    this.setState(obj)
  }

  render() {
    return (
      <BrowserRouter>
        <Row>
          <Col sm={24} className="colunaPrincipal">
            <Switch>
              <Rotas/>
            </Switch>
          </Col>
        </Row>
        
      </BrowserRouter>
    );
  }

}


export default App;
