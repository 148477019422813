import { SET_FILTROS } from '../actions/actionTypes';

const initialState = {
    filtros: []
};

export const reducerFiltros = (state = initialState, action) => {
    switch (action.type) {
        case SET_FILTROS:
            return {
                filtros: action.filtros
            };
        default:
            return state;
    }
};