import React from 'react';
import { Layout, Button, Input, Row, Col, Table, notification } from 'antd';
import Icon from '@ant-design/icons';
import './Gerenciar.scss';
import axios from 'axios';
//FUNCOES
import { getUrlApiNucardapio } from '../../_environments/ConfigAPI'
//REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setVisibleModalX } from '../../actions';
import Empresa from '../../components/empresa/Empresa';

class Gerenciar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reload: ""
        }
    }

    axiosPost = async function (url, param) {
        var ret = "";
        var urlApi = await getUrlApiNucardapio();
        var retorno = await axios.post(urlApi + url, param);
        return retorno.data;
    }

    ativarDesativar = function (cardapio, ativar) {
        cardapio.ativo = ativar;
        var self = this;
        var promisse = this.axiosPost("salvarCardapio", { cardapio: cardapio });
        promisse.then(function (resultado) {
            var msg = "Produto desativado com sucesso.";
            if (ativar) {
                msg = "Produto ativado com sucesso.";
            }

            if (resultado.length > 0) {
                notification.open({
                    message: 'Alerta',
                    description: msg
                });
            }

        })
        this.setState({reload: ""})
    }

    renderCardapio() {
        var self = this;
        const columns = [
            {
                title: 'titulo',
                dataIndex: 'titulo'
            },
            {
                title: 'descrição',
                dataIndex: 'descricao'
            },
            {
                title: 'ação',
                dataIndex: 'btnAcao'
            }
        ];

        const dataSource = []
        this.props.cardapio.map(function (x, key) {
            var btn = <Button type="primary" onClick={() => self.ativarDesativar(x, 1)}>ATIVAR</Button>
            if (x.ativo) {
                btn = <Button type="primary" onClick={() => self.ativarDesativar(x, 0)}>DESATIVAR</Button>
            }

            dataSource.push({
                key: key,
                titulo: x.nome,
                descricao: x.descricao,
                btnAcao: btn

            });
        })

        return <Table reload={self.state.reload} dataSource={dataSource} columns={columns} />
    }

    render() {
        return (
            <Layout className="pgGerenciar" key={1}>
                <div className="headerPedidos">
                        <img alt="nucardapio" style={{ float: "left", height: "40px" }} src={process.env.PUBLIC_URL + "/imagens/logos/logo-nucardapio-rosa.png"}></img>
                    </div>
                <Empresa statusAtivo="3" />
                {this.renderCardapio()}
            </Layout>
        )
    }
}

const mapStateToProps = store => ({
    cardapio: store.setCardapio.cardapio,
    idEmpresa: store.setConfiguracoes.idEmpresa
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setVisibleModalX }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Gerenciar);