import { 
    CALCULAR_TOTAL_PEDIDO
} from '../actions/actionTypes';

const initialState = {
    valorTotalPedido: 0
};


export const reducerPedido = (state = initialState, action) => {

    var calcularTotalPedido = function (carrinho) {
        var valorTotalPedido = 0;
        let keys = Object.keys(carrinho);
        keys.forEach(key => {
            var itemCarrinho = carrinho[key];
            let qtd = parseFloat(itemCarrinho.qtd);
            let preco = parseFloat(itemCarrinho.preco);
            let totalItem = qtd * preco;
            let totalAdicional = 0;
            let totalRadios = 0;
    
            if (itemCarrinho.checkboxesSelecionados) {
                Object.keys(itemCarrinho.checkboxesSelecionados).map(function (xx) {
    
                    itemCarrinho.checkboxesSelecionados[xx].map(function (x) {
                        var xi = JSON.parse(x)
                        totalAdicional += xi.valor * qtd;
                        return "";
                    })
                    return "";
                })
            }
    
    
            if (itemCarrinho.radiosSelecionados) {
                Object.keys(itemCarrinho.radiosSelecionados).map(function (titulo, key) {
                    let objRadio = JSON.parse(itemCarrinho.radiosSelecionados[titulo]);
                    let valor = parseFloat(objRadio.valor)
                    if (objRadio.substituirValorOriginal) {
                        //preco do produto vai ser o radio
                        itemCarrinho.preco = valor;
    
                        //retiro da somatoria o preco, pois foi trocado pelo radio button
                        totalItem -= preco * qtd;
                    }
                    totalRadios += valor * qtd;
                    return "";
                })
            }
    
            totalItem += parseFloat(totalAdicional) + parseFloat(totalRadios);
            valorTotalPedido += totalItem;
    
            //atualizo no carrinho o total do item
            itemCarrinho.totalItem = totalItem;
        })
        return {
            ...state,
            valorTotalPedido: valorTotalPedido
        };
    }

    
    switch (action.type) {
        case CALCULAR_TOTAL_PEDIDO: return calcularTotalPedido(action.carrinho)
        default:
            return state;
    }
};