import { 
  SET_CARRINHO,
  SET_CONFIGURACOES,
  SET_IDEMPRESA,
  SET_BANNERS,
  SET_CARDAPIO,
  SET_QTDITENS,
  CALCULAR_TOTAL_PEDIDO,
  SET_FILTROS,
  SET_VISIBLE_MODAL_X,
 } from './actionTypes';

export const setCarrinho = newCarrinho => ({
  type: SET_CARRINHO,
  carrinho: newCarrinho
});

export const setConfiguracoes = newConfiguracoes => ({
  type: SET_CONFIGURACOES,
  configuracoes: newConfiguracoes
});

export const setIdEmpresa = idEmpresa => ({
  type: SET_IDEMPRESA,
  idEmpresa: idEmpresa
});

export const setBanners = banners => ({
  type: SET_BANNERS,
  banners: banners
});

export const setCardapio = cardapio => ({
  type: SET_CARDAPIO,
  cardapio: cardapio
});

export const setQtdItens = qtdItens => ({
  type: SET_QTDITENS,
  qtdItens: qtdItens
});

export const calcularTotalPedido = carrinho => ({
  type: CALCULAR_TOTAL_PEDIDO,
  carrinho: carrinho
});

export const setFiltros = filtros => ({
  type: SET_FILTROS,
  filtros: filtros
});

export const setVisibleModalX = (id,visibilidade) => ({
  type: SET_VISIBLE_MODAL_X,
  id: id,
  visibilidade: visibilidade
});


