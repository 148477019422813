import React from 'react';
import axiosGet from '../../functions/axiosGet'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setConfiguracoes,setIdEmpresa,setBanners,setCardapio,setFiltros  } from '../../actions';

class Empresa extends React.Component {

    componentDidMount(){
        this.getDadosEmpresa();
    }

    getFiltros = async function () {
        var url = "getCategorias?empresa=" + this.props.idEmpresa;
        var retorno = await axiosGet(url);
        var todos = { titulo: "TODOS" };
        var filtros = [];
        filtros.push(todos);
        retorno.map(function (x) {
            filtros.push(x);
            return "";
        })
        this.props.setFiltros(filtros)
    }

    getCardapio = async function(){
        var statusAtivo = ""; 
        
        if(this.props.statusAtivo == 0 ||  this.props.statusAtivo == 1){
            statusAtivo = "&ativo="+this.props.statusAtivo;
        }

        var url = "getCardapioEmpresaX?empresa=" + this.props.idEmpresa+statusAtivo;
        var retorno = await axiosGet(url);
    
        var cardapio = [];
        retorno.map(function (ret) {   //feito isso para adicionar um x.adicional array no cardapio
            ret.adicional = [];
            cardapio.push(ret);
            return "";
        })
    
       this.props.setCardapio(cardapio);
    }

    getDadosEmpresa = async function () {
        var subdominio = new URLSearchParams(window.location.search).get("subdominio") || window.location.host.split(".")[0];
    
        var url = "getEmpresa?subdominio=" + subdominio;
        var retorno = await axiosGet(url);
        
        var idEmpresa = retorno.id;

        this.props.setConfiguracoes(JSON.parse(retorno.configuracoes))
        this.props.setIdEmpresa(idEmpresa)

        window.localStorage.setItem("configuracoes", JSON.stringify(retorno.configuracoes));
        window.localStorage.setItem("uuidEmpresa", JSON.stringify(retorno.uuid));
    
        url = "getBannerEmpresaX?empresa=" + idEmpresa;
        var banners = await axiosGet(url);
        this.props.setBanners(banners)
        
        this.getCardapio();
        this.getFiltros();
    }

    render(){
        return(<div></div>);
    }

}

const mapStateToProps = store => ({
    idEmpresa: store.setConfiguracoes.idEmpresa,
    configuracoes: store.setConfiguracoes.configuracoes,
    banners: store.setBanners.banners,
    cardapio: store.setCardapio.cardapio,
    filtros: store.setFiltros.filtros
})

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setIdEmpresa,setConfiguracoes,setBanners,setCardapio,setFiltros }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Empresa);