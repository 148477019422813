import React, { useState, useEffect } from 'react';
import { Select, Form, Input } from 'antd';
const { Option } = Select;

const FormItemBairro = ({ tipoFrete, getZonasEmitirNotas,handleChangeBairro, bairro="", onChangeInput=()=>{} }) => {

    const [bairrosEmpresa, setBairrosEmpresa] = useState([]);
    const iniciaZonasEmitirNotas = async () => {
        const zonas = await getZonasEmitirNotas();
        setBairrosEmpresa(zonas);
    }
 
    useEffect(() => {
        iniciaZonasEmitirNotas();
    }, []);

    if (tipoFrete && tipoFrete == "porBairro") {

        var options = [];
        bairrosEmpresa.forEach((bairro) => {
            options.push(<Option key={bairro.id} value={JSON.stringify(bairro)}>{bairro.nome}</Option>);
        })

        return (
            <Form.Item label="bairro">
                <Select name="bairro" onChange={handleChangeBairro.bind()}>
                    {options}
                </Select >
            </Form.Item>
        )

    } else {
        return <Form.Item  label="bairro">
            {/* <Input name="bairro" value={this.state.endereco.bairro} onChange={this.onChangeInput} type="text"></Input> */}
            <Input name="bairro" value={bairro} onChange={onChangeInput} type="text"></Input>
        </Form.Item>
    }

}

export { FormItemBairro };